import { initializeApp } from 'firebase/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import { updateProfile } from '@firebase/auth'
import { firebaseConfig, Environment } from '@/configs/firebase'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

if (Environment.environment == 'emulator') {
  console.log('Running on firestore emulator', Environment)
  connectAuthEmulator(auth, Environment.firebaseAuthEmulatorHost)
}

const db = getFirestore(app)
export const firebaseRegisterUser = async (name: string, email: string, password: string) => {
  try {
    const userCredentials = await createUserWithEmailAndPassword(auth, email, password)
    if (userCredentials) {
      await updateProfile(userCredentials.user, { displayName: name })
      await firebaseUpdateUser({
        name,
        email,
        uid: userCredentials.user.uid,
        photoURL: userCredentials.user.photoURL,
        provider: userCredentials.user.providerData[0].providerId,
        providerId: userCredentials.user.providerData[0].uid,
        balance: 0,
        roles: ['user'],
      })
    }
  } catch (error) {
    console.log('firebaseRegisterUser error', error)
    Sentry.captureMessage('firebaseRegisterUser', {
      extra: {
        defaultLayout: true,
        location: location.pathname,
        error: error,
      },
    })
  }
}

export const firebaseUpdateUser = async (userData: Record<string, unknown>) => {
  try {
    if (auth.currentUser) {
      const userRef = doc(db, 'users', auth.currentUser.uid)
      await setDoc(userRef, userData, { merge: true })
    }
  } catch (error) {
    console.log('firebaseUpdateUser error', error)
    Sentry.captureMessage('firebaseUpdateUser', {
      extra: {
        defaultLayout: true,
        location: location.pathname,
        error: error,
      },
    })
  }
}

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider()
    const userCredentials = await signInWithPopup(auth, provider)
    if (!userCredentials.user) throw new Error(`Couldn't sign in with Google`)
    if (userCredentials.user.metadata.creationTime === userCredentials.user.metadata.lastSignInTime) {
      await firebaseUpdateUser({
        name: userCredentials.user.displayName,
        email: userCredentials.user.email,
        uid: userCredentials.user.uid,
        photoURL: userCredentials.user.photoURL,
        provider: userCredentials.user.providerData[0].providerId,
        providerId: userCredentials.user.providerData[0].uid,
        balance: 0,
        roles: ['user'],
      })
    }
  } catch (error) {
    Sentry.captureMessage('signInWithGoogle', {
      extra: {
        defaultLayout: true,
        location: location.pathname,
        error: error,
      },
    })
  }
}
