import { GraphQLClient, RequestOptions } from 'graphql-request'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders']
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Any: { input: any; output: any }
}

export type AccrueAllInterestResult = {
  __typename?: 'AccrueAllInterestResult'
  /** Counts the number of accounts whose AccrueInterest transactions were not executed */
  accountsDelayed: Scalars['Int']['output']
}

export type AddLiquidityPoolInput = {
  assets: Array<BalanceInput>
  poolUID: Scalars['String']['input']
}

/**
 * Amount Input is a generic input type for mutations which only require a single number input.
 * Since the amount field is a string, it can be parsed as either an int or a float by the mutation.
 */
export type AmountInput = {
  amount: Scalars['String']['input']
}

export type Asset = {
  __typename?: 'Asset'
  bond?: Maybe<AssetBond>
  collateralWeight: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  decimals: Scalars['Int']['output']
  description: Scalars['String']['output']
  liquidationThreshold: Scalars['String']['output']
  maxSupply: Scalars['String']['output']
  maxUtilization: Scalars['String']['output']
  symbol: Scalars['String']['output']
  type: AssetType
  uid: Scalars['String']['output']
  usage: Array<AssetUsage>
}

export type AssetBond = {
  __typename?: 'AssetBond'
  couponStartDate: Scalars['String']['output']
  finalCouponDate: Scalars['String']['output']
  isin: Scalars['String']['output']
  issueDate: Scalars['String']['output']
  issuer: Scalars['String']['output']
  maturityDate: Scalars['String']['output']
  name: Scalars['String']['output']
  numberOfPaymentsPerYear: Scalars['Int']['output']
  /** Yearly yield */
  yield: Scalars['Float']['output']
}

export enum AssetType {
  Bond = 'Bond',
  Currency = 'Currency',
  PoolShare = 'PoolShare',
}

export enum AssetUsage {
  /** DirectBorrow enables an asset for Supply, Withdraw, and Repay as well as direct borrowing via leverage. */
  DirectBorrow = 'DirectBorrow',
  /**
   * Onboard allows transfer to and from external banks.
   * It also enables an asset for ClaimInterest and PayInterest.
   *
   * Onboard is exclusive: it should forbid Trade and Borrow usage on the same asset.
   * Possible exception: for swaps between onboarded dollar assets and a designated yieldbearing stablecoin.
   */
  Onboard = 'Onboard',
  /** PoolShare enables an asset for RemoveLiquidityFromPool */
  PoolShare = 'PoolShare',
  /** Trade enables an asset for AddLiquidityToPool and CreateOrder. */
  Trade = 'Trade',
  /** VirtualBorrow enables an asset for indirect borrowing, where assets are minted and backed by stable collateral. */
  VirtualBorrow = 'VirtualBorrow',
}

/**
 * A Balance represents an integer amount of an asset, and can be used to represent funds held by a user,
 * leverage positions, pool balances, orders, or other data.
 */
export type Balance = {
  __typename?: 'Balance'
  /**
   * Amount is a non-negative integer quantity of an asset.
   * This is the base amount with regards to the asset's exponent.
   * For example, 1.5 of an asset with exponent 3 will by represented as amount "1500".
   */
  amount: Scalars['String']['output']
  /** Asset ID specifies the bond (or stablecoin) held in this balance. */
  assetID: Scalars['String']['output']
  /**
   * SortAmount is a copy of Amount represented as a float. It may have been rounded a bit, so the original Amount
   * should still be considered as the source of truth. However, sorting and aggregation will work with this field.
   */
  sortAmount: Scalars['Float']['output']
}

export type BalanceInput = {
  amount: Scalars['String']['input']
  assetID: Scalars['String']['input']
}

export type BondInput = {
  couponStartDate: Scalars['String']['input']
  finalCouponDate: Scalars['String']['input']
  isin: Scalars['String']['input']
  issueDate: Scalars['String']['input']
  issuer: Scalars['String']['input']
  maturityDate: Scalars['String']['input']
  name: Scalars['String']['input']
  numberOfPaymentsPerYear: Scalars['Int']['input']
  yield: Scalars['Float']['input']
}

export type CancelOrderInput = {
  /** The orderbook in which to cancel a user's orders. */
  orderBookID: Scalars['String']['input']
  /** The ID of the order to cancel. Use 'ALL' to cancel all of the user's orders in one orderbook. */
  orderID: Scalars['String']['input']
}

export type Candle = {
  __typename?: 'Candle'
  close: Scalars['Float']['output']
  high: Scalars['Float']['output']
  low: Scalars['Float']['output']
  open: Scalars['Float']['output']
  /** Time is unix time in seconds truncated to the minute. */
  time: Scalars['Int']['output']
  uid: Scalars['String']['output']
  volume: Scalars['Int']['output']
}

export type CandleData = {
  __typename?: 'CandleData'
  assetOutUID: Scalars['String']['output']
  close: Scalars['Float']['output']
  high: Scalars['Float']['output']
  low: Scalars['Float']['output']
  open: Scalars['Float']['output']
  source: CandleSource
  volume: Scalars['Int']['output']
}

export enum CandleSource {
  BondMarket = 'BondMarket',
}

export type ChartData = {
  __typename?: 'ChartData'
  assetInUID: Scalars['String']['output']
  candle: CandleData
  point: PointData
  /** Time is unix time in secconds truncated to the minute. */
  time: Scalars['Int']['output']
  /** Type [price or yield] fill point, [candle] fill candle.  */
  type: ChartType
  uid: Scalars['String']['output']
}

export enum ChartType {
  Candle = 'Candle',
  Price = 'Price',
  Yield = 'Yield',
}

export type CreateAmmInput = {
  g: Scalars['Float']['input']
  poolMaturity: Scalars['Int']['input']
}

export type CreateAssetInput = {
  Bond?: InputMaybe<BondInput>
  collateralWeight: Scalars['String']['input']
  decimals: Scalars['Int']['input']
  description: Scalars['String']['input']
  liquidationThreshold: Scalars['String']['input']
  maxSupply: Scalars['String']['input']
  maxUtilization: Scalars['String']['input']
  symbol: Scalars['String']['input']
  type: AssetType
  usage: Array<AssetUsage>
}

export type CreateOrderBookInput = {
  baseUID: Scalars['String']['input']
  priceDisplayMultiplier: Scalars['Float']['input']
  quoteUID: Scalars['String']['input']
  tickSize: Scalars['Float']['input']
}

export type CreateOrderInput = {
  amountIn: Scalars['String']['input']
  amountOut: Scalars['String']['input']
  assetInID: Scalars['String']['input']
  assetOutID: Scalars['String']['input']
  inverseLeverage?: InputMaybe<Scalars['Float']['input']>
  orderType: OrderType
}

export type CreateOrderWithPriceInput = {
  amountIn?: InputMaybe<Scalars['String']['input']>
  amountOut?: InputMaybe<Scalars['String']['input']>
  assetInID: Scalars['String']['input']
  assetOutID: Scalars['String']['input']
  inverseLeverage?: InputMaybe<Scalars['Float']['input']>
  orderType: OrderType
  price: Scalars['Float']['input']
}

export type CreatePoolInput = {
  ammProp?: InputMaybe<CreateAmmInput>
  baseAsset: BalanceInput
  prodProp?: InputMaybe<CreateProductInput>
  quoteAsset: BalanceInput
}

export type CreateProductInput = {
  swapFee: Scalars['Float']['input']
}

export type DataWarning = {
  __typename?: 'DataWarning'
  message: Scalars['String']['output']
  path: Scalars['String']['output']
}

export type FloatPrice = {
  __typename?: 'FloatPrice'
  assetID: Scalars['String']['output']
  price: Scalars['Float']['output']
}

export enum InputAsset {
  Bond = 'Bond',
  Stable = 'Stable',
}

/**
 * Interest tracks an individual user's interest earnings and payments.
 * It is also used in the module to track the sum of all users.
 */
export type Interest = {
  __typename?: 'Interest'
  /** Claimed interest increases when a user claims their interest, and never decreases. */
  claimed: Scalars['String']['output']
  /** Earned interest has been earned but not yet claimed. It decreases when a user claims their interest. */
  earned: Scalars['String']['output']
  /** LastUpdated is the unix time a user's interest was last updated. Not used for module interest. */
  lastUpdated: Scalars['Int']['output']
  /** Owed interest has accrued on a user's borrows but has not yet been paid off. It decreases when the user pays. */
  owed: Scalars['String']['output']
  /** Paid interest increases when the user pays, and never decreases. */
  paid: Scalars['String']['output']
}

export type LeveragePosition = {
  __typename?: 'LeveragePosition'
  borrowed: Array<Balance>
  supplied: Array<Balance>
}

export type LeverageValue = {
  __typename?: 'LeverageValue'
  /** The maximum borrowed value a user is allowed to reach via leverage. */
  borrowLimit: Scalars['Float']['output']
  /** The total dollar value of a user's borrowed bonds and stablecoins. */
  borrowed: Scalars['Float']['output']
  /** The total dollar value of a user's assets, excluding LP shares and supplied stablecoins. */
  collateral: Scalars['Float']['output']
  /** The maximum borrowed value a user is allowed to reach before liquidation. */
  liquidationThreshold: Scalars['Float']['output']
  /** The total dollar value of a user's supplied stablecoins. */
  supplied: Scalars['Float']['output']
}

export type LiquidateInput = {
  userUID: Scalars['String']['input']
}

export type Module = {
  __typename?: 'Module'
  /**
   * balance tracks how many stablecoins have been supplied to the module by users, EXCLUDING stablecoins which have been borrowed out.
   * This is the actual amount of stablecoins held in the module, excluding interestHeld.
   * The sum amount of all balance amounts (across all asset IDs) should always be greater than the sum of all virtual bond amounts.
   */
  balance: Array<Balance>
  /**
   * borrowed tracks how many stablecoins have been borrowed directly by users (not minted as virtual assets)
   * Its amount for each stablecoin is the sum of that specific stablecoin borrowed by all users.
   * The borrowed amount of each asset cannot exceed the asset's supplied amount.
   */
  borrowed: Array<Balance>
  /** dollarAssetsUID contains the list of all assets which are considered actual dollars, and whose price is always $1 */
  dollarAssetsUID: Array<Scalars['String']['output']>
  /**
   * interestHeld contains assets paid to the module as interest by borrowers, but not yet claimed by suppliers.
   * It may contain multiple asset types if multiple assets are supported for interest payments.
   */
  interestHeld: Array<Balance>
  /**
   * supplied tracks how many stablecoins have been supplied to the module by users, INCLUDING those which are borrowed out.
   * Its amount for each asset should equal the sum of the asset supplied by all users.
   * The supplied amount of each asset cannot exceed the asset's collateral amount.
   */
  supplied: Array<Balance>
  /**
   * virtualBonds tracks how many virtual bonds have been minted by the module and borrowed out by users, but not yet repaid and burned.
   * It should not contain stablecoin assets, and its amount for each asset should equal the sum of borrows across all users.
   */
  virtualBonds: Array<Balance>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Cron Jobs */
  AccrueAllInterest: AccrueAllInterestResult
  AddBalances?: Maybe<Balance>
  AddLiquidityToPool?: Maybe<PoolBalance>
  CalculatePrices?: Maybe<Array<FloatPrice>>
  CancelOrder: Scalars['Boolean']['output']
  CancelUserOrders: Scalars['Boolean']['output']
  ClaimInterest: Transaction
  CreateAsset?: Maybe<Asset>
  /** Bond Trader Mutations */
  CreateOrder?: Maybe<Order>
  /** SuperAdmin mutations */
  CreateOrderBook?: Maybe<OrderBook>
  CreateOrderWithPrice?: Maybe<Order>
  CreatePool?: Maybe<PoolAsset>
  Liquidate: Transaction
  MatchCancellations?: Maybe<Array<Order>>
  MatchOrders?: Maybe<Array<Transaction>>
  PayInterest: Transaction
  RemoveBalances?: Maybe<Balance>
  RemoveLiquidityFromPool?: Maybe<RemoveLiquidityFromPoolResult>
  Repay: Transaction
  /** SetMatchmakingInterval changes how frequently matchmaking is called. Range 0-60 seconds (0 is disabled). */
  SetMatchmakingInterval: Scalars['Boolean']['output']
  Supply: Transaction
  /** User Mutations */
  UpdateUser?: Maybe<User>
  UpdateUsersRoles?: Maybe<User>
  Withdraw: Transaction
}

export type MutationAddBalancesArgs = {
  balanceToAdd?: InputMaybe<BalanceInput>
}

export type MutationAddLiquidityToPoolArgs = {
  addLiquidity?: InputMaybe<AddLiquidityPoolInput>
}

export type MutationCalculatePricesArgs = {
  maxDepth: Scalars['Int']['input']
}

export type MutationCancelOrderArgs = {
  input: CancelOrderInput
}

export type MutationCancelUserOrdersArgs = {
  orderBookID: Scalars['String']['input']
}

export type MutationCreateAssetArgs = {
  newAsset?: InputMaybe<CreateAssetInput>
}

export type MutationCreateOrderArgs = {
  newOrder?: InputMaybe<CreateOrderInput>
}

export type MutationCreateOrderBookArgs = {
  newOrderBook?: InputMaybe<CreateOrderBookInput>
}

export type MutationCreateOrderWithPriceArgs = {
  newOrder?: InputMaybe<CreateOrderWithPriceInput>
}

export type MutationCreatePoolArgs = {
  input?: InputMaybe<CreatePoolInput>
}

export type MutationLiquidateArgs = {
  liquidateInput: LiquidateInput
}

export type MutationMatchCancellationsArgs = {
  orderbook: Scalars['String']['input']
}

export type MutationMatchOrdersArgs = {
  orderbook: Scalars['String']['input']
}

export type MutationPayInterestArgs = {
  payInput: AmountInput
}

export type MutationRemoveBalancesArgs = {
  balanceToRmv?: InputMaybe<BalanceInput>
}

export type MutationRemoveLiquidityFromPoolArgs = {
  removeLiquidity?: InputMaybe<RemoveLiquidityFromPoolInput>
}

export type MutationRepayArgs = {
  asset: BalanceInput
}

export type MutationSetMatchmakingIntervalArgs = {
  interval: Scalars['Int']['input']
}

export type MutationSupplyArgs = {
  asset: BalanceInput
}

export type MutationUpdateUserArgs = {
  updatedUser?: InputMaybe<UpdateUserInput>
}

export type MutationUpdateUsersRolesArgs = {
  updatedRoles?: InputMaybe<UpdateUserRolesInput>
}

export type MutationWithdrawArgs = {
  asset: BalanceInput
}

export type Order = {
  __typename?: 'Order'
  /**
   * AmountIn specifies the exact amount in an order needs to be filled, if the order is a sell.
   * For limit buys, this is the maximum amount in. For market buys, it is zero.
   */
  amountIn: Scalars['String']['output']
  /**
   * AmountOut specifies the exact amount out an order needs to be filled, if the order is a buy.
   * For limit sells, this is the minimum amount out. For market sells, it is zero.
   */
  amountOut: Scalars['String']['output']
  assetInID: Scalars['String']['output']
  assetOutID: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  /** InFilled is the exact amount of assetInID which has been spent by this order. */
  inFilled: Scalars['String']['output']
  /**
   * InverseLeverage is the portion of balanceIn which should be borrowed instead of coming out of the user's balance.
   * It ranges from 0 to 1: at 0, no leverage is used. At 1, no balance is used.
   * To achieve a given leverage multilier (e.g. 8x), this should be set to the inverse (e.g. 0.125, or 1/8).
   * If this field is empty, it should be treated as 1.
   */
  inverseLeverage?: Maybe<Scalars['Float']['output']>
  orderBookID: Scalars['String']['output']
  orderID: Scalars['String']['output']
  /** OutFilled is the exact amount of assetOutID which has been bought by this order. */
  outFilled: Scalars['String']['output']
  /**
   * Price refers to the limit price of any limit order, or the executed price of any partial or completed market order.
   * It will be initialized at market price (estimated) for any market orders which have not executed.
   * Prices are always defined as the ratio of amounts assetQuote/assetBase.
   * This interpretation is independent of which asset is assetInID for this particular transaction.
   * Price will be a multiple of tickSize of the orderbook in which this order is found.
   */
  price: Scalars['Float']['output']
  /**
   * SortAmountIn is a copy of AmountIn represented as a float. It may have been rounded a bit, so the original AmountIn
   * should still be considered as the source of truth. However, sorting and aggregation will work with this field.
   */
  sortAmountIn: Scalars['Float']['output']
  /**
   * SortAmountOut is a copy of AmountOut represented as a float. It may have been rounded a bit, so the original AmountOut
   * should still be considered as the source of truth. However, sorting and aggregation will work with this field.
   */
  sortAmountOut: Scalars['Float']['output']
  /** SortInFilled is a float copy of the inFilled field, possibly rounded but usable for aggregation */
  sortInFilled: Scalars['Float']['output']
  /** SortOutFilled is a float copy of the outFilled field, possibly rounded but usable for aggregation */
  sortOutFilled: Scalars['Float']['output']
  /** SortRemainingAmountIn is the remaining amountIn saved as float for aggregation queries */
  sortRemainingAmountIn: Scalars['Float']['output']
  /** state describes where the order is in its life cycle */
  state: OrderState
  /** type defines the behavior of the order */
  type: OrderType
  updatedAt: Scalars['Int']['output']
  userUID: Scalars['String']['output']
}

export type OrderBook = {
  __typename?: 'OrderBook'
  baseUID: Scalars['String']['output']
  orderBookID: Scalars['String']['output']
  orders: Array<SimpleOrder>
  priceDisplayMultiplier: Scalars['Float']['output']
  quoteUID: Scalars['String']['output']
  tickSize: Scalars['Float']['output']
}

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** OrderCancel represents a user's pending request to cancel an order. */
export type OrderCancel = {
  __typename?: 'OrderCancel'
  orderBookID: Scalars['String']['output']
  orderID: Scalars['String']['output']
  userUID: Scalars['String']['output']
}

export enum OrderState {
  /** Canceled orders are no longer open and were not executed, even partially. */
  Canceled = 'CANCELED',
  /** Created orders are open and have zero filledIn and filledOut amounts. */
  Created = 'CREATED',
  /** Executed orders are no longer open, and have either been filled to completion or canceled after partial. */
  Executed = 'EXECUTED',
  /** Partial orders are open and have nonzero filledIn and filledOut amounts. */
  Partial = 'PARTIAL',
}

export enum OrderType {
  LimitBuy = 'LIMIT_BUY',
  LimitSell = 'LIMIT_SELL',
  MarketBuy = 'MARKET_BUY',
  MarketSell = 'MARKET_SELL',
}

export type OrdersSize = {
  __typename?: 'OrdersSize'
  price: Scalars['Float']['output']
  size: Scalars['Float']['output']
}

export type OrdersSizeResponse = {
  __typename?: 'OrdersSizeResponse'
  buys: Array<OrdersSize>
  orderBookID: Scalars['String']['output']
  sells: Array<OrdersSize>
}

/** Pagination defines the structure for pagination adapter */
export type Pagination = {
  after?: InputMaybe<Scalars['Any']['input']>
  key?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<OrderBy>
}

export type PointData = {
  __typename?: 'PointData'
  value: Scalars['Float']['output']
}

export type Pool = {
  __typename?: 'Pool'
  baseAsset: Balance
  createdAt: Scalars['Int']['output']
  poolShares: Balance
  prodProp?: Maybe<ProductProperties>
  quoteAsset: Balance
  type: PoolType
  uid: Scalars['String']['output']
  yieldSpaceProp?: Maybe<YieldSpaceProp>
}

export type PoolAsset = {
  __typename?: 'PoolAsset'
  assetLP: Asset
  pool: Pool
}

export type PoolBalance = {
  __typename?: 'PoolBalance'
  newLiquidityShare: Scalars['String']['output']
  pool: Pool
}

export type PoolOrdersSummary = {
  __typename?: 'PoolOrdersSummary'
  midPrice: Scalars['Float']['output']
  orderBookID: Scalars['String']['output']
  spread: Scalars['Float']['output']
}

export enum PoolType {
  ConstantProduct = 'ConstantProduct',
  YieldSpace = 'YieldSpace',
}

export type ProductProperties = {
  __typename?: 'ProductProperties'
  swapFee: Scalars['Float']['output']
}

export type Query = {
  __typename?: 'Query'
  /** CheckData runs Validate() on all data in the database, and returns the data path and error message of each error. */
  checkData: Array<DataWarning>
  /**
   * DevQuery should be used for arbitrary debugging against the backend.
   * Input and output are kept as strings so the schema does not need to be changed.
   * Authentication is omitted for reliability's sake.
   */
  devQuery: Scalars['String']['output']
  exploreTxs?: Maybe<Array<Maybe<Transaction>>>
  getAdminFromAuth?: Maybe<User>
  getAllAssets: Array<Asset>
  getAllOrderBooks: Array<OrderBook>
  getAllOrders: Array<Order>
  getAllPools: Array<Pool>
  getAllUsers: Array<User>
  getAsset: Asset
  getCandles: Array<Candle>
  getOrderBook: OrderBook
  getOrders: Array<Order>
  getPointChart: Array<ChartData>
  getPoolOrdersSummary: PoolOrdersSummary
  getSwapRoute?: Maybe<Array<Maybe<RouteValue>>>
  getTradedVolume: TradedVolume
  getTxs?: Maybe<Array<Maybe<Transaction>>>
  getUser?: Maybe<User>
  getUserBalances: Array<Balance>
  getUserCustomToken: Scalars['String']['output']
  getUserFromAuth?: Maybe<User>
  lastTradeDollarAsset: Trade
  liquidationTargets: Array<Scalars['String']['output']>
  moduleInterest?: Maybe<Interest>
  moduleInterestHeld: Array<Balance>
  orderBookOpenOrders: OrdersSizeResponse
  /** ResetRateLimit will reset the backend's read and write rate limits, without modifying the DB. */
  resetRateLimit: Scalars['Boolean']['output']
  simulateOrder: SwapResult
  swapPrice?: Maybe<SwapResult>
  tradeHistory: Array<Trade>
  userBalances?: Maybe<UserBalances>
  userInterest?: Maybe<Interest>
  userLeveragePositions?: Maybe<LeveragePosition>
  userLeverageValue: LeverageValue
  userOpenOrders: Array<Order>
  userOrderBookOrders: Array<Order>
  userOrders: Array<Order>
}

export type QueryDevQueryArgs = {
  input: Scalars['String']['input']
}

export type QueryExploreTxsArgs = {
  assetsUID?: InputMaybe<Array<Scalars['String']['input']>>
  from?: InputMaybe<Scalars['Int']['input']>
  orderBookID?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<Pagination>
  to?: InputMaybe<Scalars['Int']['input']>
  txID?: InputMaybe<Scalars['String']['input']>
  txTypes?: InputMaybe<Array<TransactionType>>
  userUID?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetAllAssetsArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryGetAllOrdersArgs = {
  orderBookID: Scalars['String']['input']
  orderState?: InputMaybe<OrderState>
  orderType?: InputMaybe<OrderType>
}

export type QueryGetAllPoolsArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryGetAssetArgs = {
  assetID: Scalars['String']['input']
}

export type QueryGetCandlesArgs = {
  assetInUID: Scalars['String']['input']
  assetOutUID: Scalars['String']['input']
  from: Scalars['Int']['input']
  resolution: Scalars['String']['input']
  to: Scalars['Int']['input']
}

export type QueryGetOrderBookArgs = {
  merged: Scalars['Boolean']['input']
  orderBookID: Scalars['String']['input']
  withOrders: Scalars['Boolean']['input']
}

export type QueryGetOrdersArgs = {
  orderBookID: Scalars['String']['input']
  orderState?: InputMaybe<OrderState>
  orderType?: InputMaybe<OrderType>
  pagination?: InputMaybe<Pagination>
}

export type QueryGetPointChartArgs = {
  assetUID: Scalars['String']['input']
  chartType: ChartType
  from?: InputMaybe<Scalars['Int']['input']>
  to?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetPoolOrdersSummaryArgs = {
  orderBookID: Scalars['String']['input']
}

export type QueryGetSwapRouteArgs = {
  amtIn: Scalars['String']['input']
  assetInUID: Scalars['String']['input']
  assetOutUID: Scalars['String']['input']
}

export type QueryGetTradedVolumeArgs = {
  assetInUID: Scalars['String']['input']
  assetOutUID: Scalars['String']['input']
  fromUnixTime: Scalars['Int']['input']
}

export type QueryGetTxsArgs = {
  ids: Array<Scalars['String']['input']>
  pagination?: InputMaybe<Pagination>
}

export type QueryGetUserArgs = {
  userUID: Scalars['String']['input']
}

export type QueryGetUserCustomTokenArgs = {
  userUID: Scalars['String']['input']
}

export type QueryLastTradeDollarAssetArgs = {
  assetUID: Scalars['String']['input']
}

export type QueryOrderBookOpenOrdersArgs = {
  orderBookID: Scalars['String']['input']
}

export type QuerySimulateOrderArgs = {
  swapInput?: InputMaybe<SwapInput>
}

export type QuerySwapPriceArgs = {
  swapPrice?: InputMaybe<SwapPrice>
}

export type QueryTradeHistoryArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryUserLeverageValueArgs = {
  userUID: Scalars['String']['input']
}

export type QueryUserOpenOrdersArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryUserOrderBookOrdersArgs = {
  orderBookID: Scalars['String']['input']
}

export type QueryUserOrdersArgs = {
  pagination?: InputMaybe<Pagination>
}

export type RemoveLiquidityFromPoolInput = {
  poolUID: Scalars['String']['input']
  shares: Scalars['String']['input']
}

export type RemoveLiquidityFromPoolResult = {
  __typename?: 'RemoveLiquidityFromPoolResult'
  assetsOut: Array<Balance>
  pool: Pool
}

export type RouteValue = {
  __typename?: 'RouteValue'
  amtIn: Scalars['String']['output']
  amtOut: Scalars['String']['output']
  assetIn: Scalars['String']['output']
  assetOut: Scalars['String']['output']
  pool: Scalars['String']['output']
}

export type SimpleOrder = {
  __typename?: 'SimpleOrder'
  amountIn: Scalars['String']['output']
  amountOut: Scalars['String']['output']
  assetInID: Scalars['String']['output']
  assetOutID: Scalars['String']['output']
  orderBookID: Scalars['String']['output']
  price: Scalars['Float']['output']
  type: OrderType
}

export type Subscription = {
  __typename?: 'Subscription'
  candlesUpdated: Array<Candle>
  lastTrade?: Maybe<Trade>
  orderBookUpdated?: Maybe<OrdersSizeResponse>
  userBalancesUpdated: UserBalances
  userOrderChanged?: Maybe<Array<Maybe<Order>>>
}

export type SubscriptionCandlesUpdatedArgs = {
  marketID: Scalars['String']['input']
  resolution: Scalars['String']['input']
}

export type SubscriptionLastTradeArgs = {
  orderBookID: Scalars['String']['input']
  poolUID: Scalars['String']['input']
}

export type SubscriptionOrderBookUpdatedArgs = {
  orderBookID: Scalars['String']['input']
}

export type SubscriptionUserBalancesUpdatedArgs = {
  userUID: Scalars['String']['input']
}

export type SubscriptionUserOrderChangedArgs = {
  orderBookID: Scalars['String']['input']
  userUID: Scalars['String']['input']
}

export type SwapInput = {
  assetIn: BalanceInput
  minAmtOut: BalanceInput
  poolUID: Scalars['String']['input']
}

export type SwapPrice = {
  assetIn: BalanceInput
  poolUID: Scalars['String']['input']
}

export type SwapResult = {
  __typename?: 'SwapResult'
  balanceOut: Balance
  fee: Balance
  pool: Pool
  tx: Transaction
}

export type TickerPrice = {
  __typename?: 'TickerPrice'
  baseAssetUID: Scalars['String']['output']
  lastPrice: Scalars['String']['output']
  quoteAssetUID: Scalars['String']['output']
  timestamp: Scalars['Int']['output']
  volume: Scalars['Int']['output']
  volume24h: Scalars['Int']['output']
}

export type Trade = {
  __typename?: 'Trade'
  amountIn: Scalars['Int']['output']
  amountOut: Scalars['Int']['output']
  assetInUID: Scalars['String']['output']
  assetOutUID: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  match: TradeMatch
  orderBookUID: Scalars['String']['output']
  poolUID: Scalars['String']['output']
  priceInToOut: Scalars['String']['output']
  priceOutToIn: Scalars['String']['output']
  txID: Scalars['String']['output']
  uid: Scalars['String']['output']
  userAssetInUID: Scalars['String']['output']
  userAssetOutUID: Scalars['String']['output']
}

export enum TradeMatch {
  OrderBookMatch = 'OrderBookMatch',
  PoolTrade = 'PoolTrade',
}

export enum TradeStatus {
  Closed = 'CLOSED',
  Liquidated = 'LIQUIDATED',
  Open = 'OPEN',
}

export enum TradeType {
  Long = 'LONG',
  Short = 'SHORT',
}

export type TradedVolume = {
  __typename?: 'TradedVolume'
  assetInID: Scalars['String']['output']
  assetOutID: Scalars['String']['output']
  fromUnixTime: Scalars['Int']['output']
  volume: Scalars['Int']['output']
}

export type Transaction = {
  __typename?: 'Transaction'
  accrueInterest?: Maybe<TxAccrueInterest>
  assetsUID?: Maybe<Array<Scalars['String']['output']>>
  claimInterest?: Maybe<TxClaimInterest>
  createdAt: Scalars['Int']['output']
  liquidate?: Maybe<TxLiquidate>
  matchOrder?: Maybe<TxMatchOrder>
  orderBookID: Scalars['String']['output']
  payInterest?: Maybe<TxPayInterest>
  repay?: Maybe<TxRepay>
  /**
   * These name=transaction-type fields) are null unless the transaction is of the specified type.
   * Only one will be non-null.
   */
  supply?: Maybe<TxSupply>
  swap?: Maybe<TxSwap>
  txID: Scalars['String']['output']
  type: TransactionType
  userUID: Scalars['String']['output']
  withdraw?: Maybe<TxWithdraw>
}

export enum TransactionType {
  AccrueInterest = 'AccrueInterest',
  AddLiquidity = 'AddLiquidity',
  ClaimInterest = 'ClaimInterest',
  Liquidate = 'Liquidate',
  MatchOrder = 'MatchOrder',
  PayInterest = 'PayInterest',
  RemoveLiquidity = 'RemoveLiquidity',
  Repay = 'Repay',
  Supply = 'Supply',
  Swap = 'Swap',
  Withdraw = 'Withdraw',
}

export type TxAccrueInterest = {
  __typename?: 'TxAccrueInterest'
  earned: Scalars['String']['output']
  fromUnixTime: Scalars['Int']['output']
  owed: Scalars['String']['output']
  toUnixTime: Scalars['Int']['output']
}

export type TxClaimInterest = {
  __typename?: 'TxClaimInterest'
  balanceOut: Balance
  claimed: Scalars['String']['output']
}

export type TxLiquidate = {
  __typename?: 'TxLiquidate'
  /** BalanceIn is the swap balance in, and also the collateral which was lost by the target user */
  balanceIn: Balance
  /** BalanceOut is the swap balance out, and also the debt which was repaid on behalf of the target user */
  balanceOut: Balance
}

/**
 * TxMatchOrder is generated when two orders match, so orderbook activity appears in transaction history.
 * Two mirror copies of this transaction are generated (one on each user) for it to appear in both tx histories.
 */
export type TxMatchOrder = {
  __typename?: 'TxMatchOrder'
  /** BalanceIn is the balance lost by this user */
  balanceIn: Balance
  /** BalanceOut is the balance gained by this user */
  balanceOut: Balance
  /** Borrowed is the balance borrowed by this user to augment balanceIn, if leverage was used. Empty otherwise. */
  borrowed?: Maybe<Balance>
  /** CounterOrderID is the other order which was matched. */
  counterOrderID: Scalars['String']['output']
  /** CounterUserID is the user's ID of the counter order. */
  counterUserID: Scalars['String']['output']
  /** OrderID is the user's order which was matched. */
  orderID: Scalars['String']['output']
  /** Price is the actual price at execution of this order match */
  price: Scalars['Float']['output']
}

export type TxPayInterest = {
  __typename?: 'TxPayInterest'
  balanceIn: Balance
  paid: Scalars['String']['output']
}

export type TxRepay = {
  __typename?: 'TxRepay'
  balanceIn: Balance
}

export type TxSupply = {
  __typename?: 'TxSupply'
  balanceIn: Balance
}

export type TxSwap = {
  __typename?: 'TxSwap'
  balanceIn: Balance
  balanceOut: Balance
  borrowed?: Maybe<Balance>
  /** orderID is the ID the associated order which causes this swap to execute */
  orderID: Scalars['String']['output']
  price: Scalars['Float']['output']
}

export type TxWithdraw = {
  __typename?: 'TxWithdraw'
  balanceOut: Balance
}

export type UpdateUserInput = {
  name?: InputMaybe<Scalars['String']['input']>
  photoURL?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  providerID?: InputMaybe<Scalars['String']['input']>
  uid: Scalars['String']['input']
}

export type UpdateUserRolesInput = {
  roles: Array<UserRole>
  uid: Scalars['String']['input']
}

export type User = {
  __typename?: 'User'
  accountClosed: Scalars['Boolean']['output']
  /**
   * Balances include any assets a user is currently in possession of.
   * This excludes those supplied to leverage or provided to pools, but includes pool shares.
   */
  balances: Array<Balance>
  /**
   * Borrowed contains any assets currently owed to the leverage module by the user, excluding interest.
   * In the case of bonds, these are virtual bonds minted by the leverage module using account value as collateral.
   * For stablecoins, this contains assets borrowed from the leverage module which were supplied by other users.
   */
  borrowed: Array<Balance>
  currency?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  /** History is a collection of all this user's transactions. */
  history: Array<Transaction>
  lastLoginDate: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
  photoURL?: Maybe<Scalars['String']['output']>
  provider?: Maybe<Scalars['String']['output']>
  providerID?: Maybe<Scalars['String']['output']>
  registrationDate: Scalars['Int']['output']
  roles: Array<UserRole>
  /**
   * Supplied contains stablecoins which a user has made available for borrowing by other users in exchange for earning interest.
   * These assets are no longer present in Balances until they are withdrawn from leverage.
   */
  supplied: Array<Balance>
  timezone?: Maybe<Scalars['String']['output']>
  /** UID uniquely identifies a user in the Users collection, and also appears in the user's orders and other associated data. */
  uid: Scalars['String']['output']
}

export type UserBalances = {
  __typename?: 'UserBalances'
  available: Array<Balance>
  locked: Array<Balance>
  total: Array<Balance>
}

/** Users can have one or more roles */
export enum UserRole {
  Admin = 'Admin',
  /** BondTrader allows placing orders and borrowing, and adding liquidity to pools */
  BondTrader = 'BondTrader',
  SuperAdmin = 'SuperAdmin',
}

export type YieldSpaceProp = {
  __typename?: 'YieldSpaceProp'
  g: Scalars['Float']['output']
  maturityAt: Scalars['Int']['output']
}

export type CreateAssetMutationVariables = Exact<{
  newAsset?: InputMaybe<CreateAssetInput>
}>

export type CreateAssetMutation = {
  __typename?: 'Mutation'
  CreateAsset?: { __typename?: 'Asset'; uid: string } | null
}

export type CreateOrderMutationVariables = Exact<{
  newOrder?: InputMaybe<CreateOrderInput>
}>

export type CreateOrderMutation = {
  __typename?: 'Mutation'
  CreateOrder?: { __typename?: 'Order'; orderBookID: string; orderID: string; inverseLeverage?: number | null } | null
}

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput
}>

export type CancelOrderMutation = { __typename?: 'Mutation'; CancelOrder: boolean }

export type CreatePoolMutationVariables = Exact<{
  input?: InputMaybe<CreatePoolInput>
}>

export type CreatePoolMutation = {
  __typename?: 'Mutation'
  CreatePool?: { __typename?: 'PoolAsset'; pool: { __typename?: 'Pool'; uid: string } } | null
}

export type AddLiquidityToPoolMutationVariables = Exact<{
  addLiquidity?: InputMaybe<AddLiquidityPoolInput>
}>

export type AddLiquidityToPoolMutation = {
  __typename?: 'Mutation'
  AddLiquidityToPool?: { __typename?: 'PoolBalance'; pool: { __typename?: 'Pool'; uid: string } } | null
}

export type RemoveLiquidityFromPoolMutationVariables = Exact<{
  removeLiquidity?: InputMaybe<RemoveLiquidityFromPoolInput>
}>

export type RemoveLiquidityFromPoolMutation = {
  __typename?: 'Mutation'
  RemoveLiquidityFromPool?: {
    __typename?: 'RemoveLiquidityFromPoolResult'
    assetsOut: Array<{ __typename?: 'Balance'; amount: string; assetID: string }>
    pool: { __typename?: 'Pool'; uid: string }
  } | null
}

export type MutationMutationVariables = Exact<{
  updatedUser?: InputMaybe<UpdateUserInput>
}>

export type MutationMutation = {
  __typename?: 'Mutation'
  UpdateUser?: {
    __typename?: 'User'
    email: string
    name?: string | null
    photoURL?: string | null
    roles: Array<UserRole>
  } | null
}

export type AddBalancesMutationVariables = Exact<{
  balanceToAdd?: InputMaybe<BalanceInput>
}>

export type AddBalancesMutation = {
  __typename?: 'Mutation'
  AddBalances?: { __typename?: 'Balance'; amount: string; assetID: string } | null
}

export type RemoveBalancesMutationVariables = Exact<{
  balanceToRmv?: InputMaybe<BalanceInput>
}>

export type RemoveBalancesMutation = {
  __typename?: 'Mutation'
  RemoveBalances?: { __typename?: 'Balance'; amount: string; assetID: string } | null
}

export type SupplyMutationVariables = Exact<{
  asset: BalanceInput
}>

export type SupplyMutation = {
  __typename?: 'Mutation'
  Supply: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type CollateralizeMutationVariables = Exact<{
  asset: BalanceInput
}>

export type CollateralizeMutation = {
  __typename?: 'Mutation'
  Supply: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type WithdrawMutationVariables = Exact<{
  asset: BalanceInput
}>

export type WithdrawMutation = {
  __typename?: 'Mutation'
  Withdraw: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type RepayMutationVariables = Exact<{
  asset: BalanceInput
}>

export type RepayMutation = {
  __typename?: 'Mutation'
  Repay: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type GetAssetQueryVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type GetAssetQuery = {
  __typename?: 'Query'
  getAsset: {
    __typename?: 'Asset'
    collateralWeight: string
    createdAt: number
    decimals: number
    description: string
    liquidationThreshold: string
    maxSupply: string
    maxUtilization: string
    symbol: string
    type: AssetType
    uid: string
    usage: Array<AssetUsage>
    bond?: {
      __typename?: 'AssetBond'
      couponStartDate: string
      finalCouponDate: string
      isin: string
      issueDate: string
      issuer: string
      maturityDate: string
      name: string
      numberOfPaymentsPerYear: number
      yield: number
    } | null
  }
}

export type GetAllAssetsQueryVariables = Exact<{
  pagination: Pagination
}>

export type GetAllAssetsQuery = {
  __typename?: 'Query'
  getAllAssets: Array<{
    __typename?: 'Asset'
    uid: string
    decimals: number
    description: string
    symbol: string
    type: AssetType
    usage: Array<AssetUsage>
    collateralWeight: string
    liquidationThreshold: string
    maxUtilization: string
    maxSupply: string
    bond?: {
      __typename?: 'AssetBond'
      couponStartDate: string
      finalCouponDate: string
      isin: string
      issueDate: string
      issuer: string
      maturityDate: string
      name: string
      numberOfPaymentsPerYear: number
      yield: number
    } | null
  }>
}

export type LastTradeDollarAssetQueryVariables = Exact<{
  assetUid: Scalars['String']['input']
}>

export type LastTradeDollarAssetQuery = {
  __typename?: 'Query'
  lastTradeDollarAsset: {
    __typename?: 'Trade'
    amountIn: number
    amountOut: number
    assetInUID: string
    assetOutUID: string
    createdAt: number
    match: TradeMatch
    orderBookUID: string
    poolUID: string
    priceInToOut: string
    priceOutToIn: string
    uid: string
    userAssetInUID: string
    userAssetOutUID: string
  }
}

export type GetCandlesQueryVariables = Exact<{
  assetInUid: Scalars['String']['input']
  assetOutUid: Scalars['String']['input']
  from: Scalars['Int']['input']
  to: Scalars['Int']['input']
  resolution: Scalars['String']['input']
}>

export type GetCandlesQuery = {
  __typename?: 'Query'
  getCandles: Array<{
    __typename?: 'Candle'
    time: number
    open: number
    close: number
    high: number
    low: number
    volume: number
  }>
}

export type ExploreTxsQueryVariables = Exact<{
  txTypes?: InputMaybe<Array<TransactionType> | TransactionType>
  orderBookId?: InputMaybe<Scalars['String']['input']>
  from?: InputMaybe<Scalars['Int']['input']>
  userUid?: InputMaybe<Scalars['String']['input']>
  to?: InputMaybe<Scalars['Int']['input']>
  assetsUid?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  txId?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<Pagination>
}>

export type ExploreTxsQuery = {
  __typename?: 'Query'
  exploreTxs?: Array<{
    __typename?: 'Transaction'
    txID: string
    type: TransactionType
    orderBookID: string
    createdAt: number
    userUID: string
    assetsUID?: Array<string> | null
    swap?: { __typename?: 'TxSwap'; price: number } | null
  } | null> | null
}

export type GetOrderBookQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
  withOrders: Scalars['Boolean']['input']
  merged: Scalars['Boolean']['input']
}>

export type GetOrderBookQuery = {
  __typename?: 'Query'
  getOrderBook: {
    __typename?: 'OrderBook'
    baseUID: string
    orderBookID: string
    priceDisplayMultiplier: number
    quoteUID: string
    tickSize: number
    orders: Array<{
      __typename?: 'SimpleOrder'
      orderBookID: string
      assetInID: string
      assetOutID: string
      amountIn: string
      amountOut: string
      price: number
      type: OrderType
    }>
  }
}

export type GetAllOrderBooksQueryVariables = Exact<{ [key: string]: never }>

export type GetAllOrderBooksQuery = {
  __typename?: 'Query'
  getAllOrderBooks: Array<{
    __typename?: 'OrderBook'
    orderBookID: string
    baseUID: string
    quoteUID: string
    tickSize: number
    priceDisplayMultiplier: number
    orders: Array<{
      __typename?: 'SimpleOrder'
      orderBookID: string
      assetInID: string
      assetOutID: string
      amountIn: string
      amountOut: string
      price: number
      type: OrderType
    }>
  }>
}

export type OrderBookOpenOrdersQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type OrderBookOpenOrdersQuery = {
  __typename?: 'Query'
  orderBookOpenOrders: {
    __typename?: 'OrdersSizeResponse'
    buys: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
    sells: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
  }
}

export type GetAllOrdersQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
  orderType?: InputMaybe<OrderType>
  orderState?: InputMaybe<OrderState>
}>

export type GetAllOrdersQuery = {
  __typename?: 'Query'
  getAllOrders: Array<{
    __typename?: 'Order'
    amountIn: string
    amountOut: string
    assetInID: string
    assetOutID: string
    createdAt: number
    inFilled: string
    orderBookID: string
    orderID: string
    outFilled: string
    price: number
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
    state: OrderState
    type: OrderType
    updatedAt: number
    userUID: string
  }>
}

export type UserOpenOrdersQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>
}>

export type UserOpenOrdersQuery = {
  __typename?: 'Query'
  userOpenOrders: Array<{
    __typename?: 'Order'
    amountIn: string
    amountOut: string
    assetInID: string
    assetOutID: string
    createdAt: number
    inFilled: string
    inverseLeverage?: number | null
    orderBookID: string
    orderID: string
    outFilled: string
    price: number
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
    state: OrderState
    type: OrderType
    updatedAt: number
    userUID: string
  }>
}

export type UserOrdersQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>
}>

export type UserOrdersQuery = {
  __typename?: 'Query'
  userOrders: Array<{
    __typename?: 'Order'
    amountIn: string
    amountOut: string
    assetInID: string
    assetOutID: string
    createdAt: number
    inFilled: string
    inverseLeverage?: number | null
    orderBookID: string
    orderID: string
    outFilled: string
    price: number
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
    state: OrderState
    type: OrderType
    updatedAt: number
    userUID: string
  }>
}

export type UserLeveragePositionsQueryVariables = Exact<{ [key: string]: never }>

export type UserLeveragePositionsQuery = {
  __typename?: 'Query'
  userLeveragePositions?: {
    __typename?: 'LeveragePosition'
    borrowed: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    supplied: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  } | null
}

export type GetAllPoolsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>
}>

export type GetAllPoolsQuery = {
  __typename?: 'Query'
  getAllPools: Array<{
    __typename?: 'Pool'
    uid: string
    type: PoolType
    createdAt: number
    baseAsset: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    quoteAsset: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    poolShares: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    yieldSpaceProp?: { __typename?: 'YieldSpaceProp'; g: number; maturityAt: number } | null
    prodProp?: { __typename?: 'ProductProperties'; swapFee: number } | null
  }>
}

export type GetPoolOrdersSummaryQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type GetPoolOrdersSummaryQuery = {
  __typename?: 'Query'
  getPoolOrdersSummary: { __typename?: 'PoolOrdersSummary'; midPrice: number; orderBookID: string; spread: number }
}

export type SwapPriceQueryVariables = Exact<{
  swapPrice?: InputMaybe<SwapPrice>
}>

export type SwapPriceQuery = {
  __typename?: 'Query'
  swapPrice?: {
    __typename?: 'SwapResult'
    pool: { __typename?: 'Pool'; uid: string }
    tx: { __typename?: 'Transaction'; txID: string }
  } | null
}

export type TradeHistoryQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>
}>

export type TradeHistoryQuery = {
  __typename?: 'Query'
  tradeHistory: Array<{
    __typename?: 'Trade'
    amountIn: number
    amountOut: number
    assetInUID: string
    assetOutUID: string
    createdAt: number
    match: TradeMatch
    orderBookUID: string
    poolUID: string
    priceInToOut: string
    priceOutToIn: string
    uid: string
    userAssetInUID: string
    userAssetOutUID: string
  }>
}

export type GetUserQueryVariables = Exact<{
  userUid: Scalars['String']['input']
}>

export type GetUserQuery = {
  __typename?: 'Query'
  getUser?: {
    __typename?: 'User'
    uid: string
    email: string
    name?: string | null
    photoURL?: string | null
    provider?: string | null
    providerID?: string | null
    roles: Array<UserRole>
    registrationDate: number
    lastLoginDate: number
  } | null
}

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>

export type GetAllUsersQuery = {
  __typename?: 'Query'
  getAllUsers: Array<{ __typename?: 'User'; uid: string; roles: Array<UserRole>; name?: string | null; email: string }>
}

export type UpdateUsersRolesMutationVariables = Exact<{
  updatedRoles?: InputMaybe<UpdateUserRolesInput>
}>

export type UpdateUsersRolesMutation = {
  __typename?: 'Mutation'
  UpdateUsersRoles?: { __typename?: 'User'; uid: string; roles: Array<UserRole> } | null
}

export type UserInterestQueryVariables = Exact<{ [key: string]: never }>

export type UserInterestQuery = {
  __typename?: 'Query'
  userInterest?: { __typename?: 'Interest'; earned: string; owed: string } | null
}

export type ModuleInterestQueryVariables = Exact<{ [key: string]: never }>

export type ModuleInterestQuery = {
  __typename?: 'Query'
  moduleInterest?: { __typename?: 'Interest'; earned: string; owed: string } | null
}

export type ModuleInterestHeldQueryVariables = Exact<{ [key: string]: never }>

export type ModuleInterestHeldQuery = {
  __typename?: 'Query'
  moduleInterestHeld: Array<{ __typename?: 'Balance'; amount: string; assetID: string }>
}

export type UserOrderBookOrdersQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type UserOrderBookOrdersQuery = {
  __typename?: 'Query'
  userOrderBookOrders: Array<{
    __typename?: 'Order'
    orderID: string
    orderBookID: string
    userUID: string
    createdAt: number
    updatedAt: number
    type: OrderType
    state: OrderState
    assetInID: string
    assetOutID: string
    amountIn: string
    amountOut: string
    inFilled: string
    outFilled: string
    price: number
    inverseLeverage?: number | null
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
  }>
}

export type UserBalancesQueryVariables = Exact<{ [key: string]: never }>

export type UserBalancesQuery = {
  __typename?: 'Query'
  userBalances?: {
    __typename?: 'UserBalances'
    total: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    available: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    locked: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  } | null
}

export type OrderBookUpdatedSubscriptionVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type OrderBookUpdatedSubscription = {
  __typename?: 'Subscription'
  orderBookUpdated?: {
    __typename?: 'OrdersSizeResponse'
    sells: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
    buys: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
  } | null
}

export type UserOrderChangedSubscriptionVariables = Exact<{
  orderBookId: Scalars['String']['input']
  userUid: Scalars['String']['input']
}>

export type UserOrderChangedSubscription = {
  __typename?: 'Subscription'
  userOrderChanged?: Array<{
    __typename?: 'Order'
    amountIn: string
    amountOut: string
    assetInID: string
    assetOutID: string
    createdAt: number
    inFilled: string
    inverseLeverage?: number | null
    orderBookID: string
    orderID: string
    outFilled: string
    price: number
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
    state: OrderState
    type: OrderType
    updatedAt: number
    userUID: string
  } | null> | null
}

export type CandlesUpdatedSubscriptionVariables = Exact<{
  marketId: Scalars['String']['input']
  resolution: Scalars['String']['input']
}>

export type CandlesUpdatedSubscription = {
  __typename?: 'Subscription'
  candlesUpdated: Array<{
    __typename?: 'Candle'
    close: number
    high: number
    low: number
    open: number
    time: number
    volume: number
  }>
}

export type UserBalancesUpdatedSubscriptionVariables = Exact<{
  userUid: Scalars['String']['input']
}>

export type UserBalancesUpdatedSubscription = {
  __typename?: 'Subscription'
  userBalancesUpdated: {
    __typename?: 'UserBalances'
    available: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    locked: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  }
}

export const CreateAssetDocument = gql`
  mutation CreateAsset($newAsset: CreateAssetInput) {
    CreateAsset(newAsset: $newAsset) {
      uid
    }
  }
`
export const CreateOrderDocument = gql`
  mutation CreateOrder($newOrder: CreateOrderInput) {
    CreateOrder(newOrder: $newOrder) {
      orderBookID
      orderID
      inverseLeverage
    }
  }
`
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    CancelOrder(input: $input)
  }
`
export const CreatePoolDocument = gql`
  mutation CreatePool($input: CreatePoolInput) {
    CreatePool(input: $input) {
      pool {
        uid
      }
    }
  }
`
export const AddLiquidityToPoolDocument = gql`
  mutation AddLiquidityToPool($addLiquidity: AddLiquidityPoolInput) {
    AddLiquidityToPool(addLiquidity: $addLiquidity) {
      pool {
        uid
      }
    }
  }
`
export const RemoveLiquidityFromPoolDocument = gql`
  mutation RemoveLiquidityFromPool($removeLiquidity: RemoveLiquidityFromPoolInput) {
    RemoveLiquidityFromPool(removeLiquidity: $removeLiquidity) {
      assetsOut {
        amount
        assetID
      }
      pool {
        uid
      }
    }
  }
`
export const MutationDocument = gql`
  mutation Mutation($updatedUser: UpdateUserInput) {
    UpdateUser(updatedUser: $updatedUser) {
      email
      name
      photoURL
      roles
    }
  }
`
export const AddBalancesDocument = gql`
  mutation AddBalances($balanceToAdd: BalanceInput) {
    AddBalances(balanceToAdd: $balanceToAdd) {
      amount
      assetID
    }
  }
`
export const RemoveBalancesDocument = gql`
  mutation RemoveBalances($balanceToRmv: BalanceInput) {
    RemoveBalances(balanceToRmv: $balanceToRmv) {
      amount
      assetID
    }
  }
`
export const SupplyDocument = gql`
  mutation Supply($asset: BalanceInput!) {
    Supply(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const CollateralizeDocument = gql`
  mutation Collateralize($asset: BalanceInput!) {
    Supply(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const WithdrawDocument = gql`
  mutation Withdraw($asset: BalanceInput!) {
    Withdraw(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const RepayDocument = gql`
  mutation Repay($asset: BalanceInput!) {
    Repay(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const GetAssetDocument = gql`
  query GetAsset($assetId: String!) {
    getAsset(assetID: $assetId) {
      bond {
        couponStartDate
        finalCouponDate
        isin
        issueDate
        issuer
        maturityDate
        name
        numberOfPaymentsPerYear
        yield
      }
      collateralWeight
      createdAt
      decimals
      description
      liquidationThreshold
      maxSupply
      maxUtilization
      symbol
      type
      uid
      usage
    }
  }
`
export const GetAllAssetsDocument = gql`
  query GetAllAssets($pagination: Pagination!) {
    getAllAssets(pagination: $pagination) {
      uid
      decimals
      description
      symbol
      type
      usage
      collateralWeight
      liquidationThreshold
      maxUtilization
      maxSupply
      bond {
        couponStartDate
        finalCouponDate
        isin
        issueDate
        issuer
        maturityDate
        name
        numberOfPaymentsPerYear
        yield
      }
    }
  }
`
export const LastTradeDollarAssetDocument = gql`
  query LastTradeDollarAsset($assetUid: String!) {
    lastTradeDollarAsset(assetUID: $assetUid) {
      amountIn
      amountOut
      assetInUID
      assetOutUID
      createdAt
      match
      orderBookUID
      poolUID
      priceInToOut
      priceOutToIn
      uid
      userAssetInUID
      userAssetOutUID
    }
  }
`
export const GetCandlesDocument = gql`
  query GetCandles($assetInUid: String!, $assetOutUid: String!, $from: Int!, $to: Int!, $resolution: String!) {
    getCandles(assetInUID: $assetInUid, assetOutUID: $assetOutUid, from: $from, to: $to, resolution: $resolution) {
      time
      open
      close
      high
      low
      volume
    }
  }
`
export const ExploreTxsDocument = gql`
  query ExploreTxs(
    $txTypes: [TransactionType!]
    $orderBookId: String
    $from: Int
    $userUid: String
    $to: Int
    $assetsUid: [String!]
    $txId: String
    $pagination: Pagination
  ) {
    exploreTxs(
      assetsUID: $assetsUid
      from: $from
      orderBookID: $orderBookId
      pagination: $pagination
      to: $to
      txID: $txId
      txTypes: $txTypes
      userUID: $userUid
    ) {
      txID
      type
      orderBookID
      createdAt
      userUID
      assetsUID
      swap {
        price
      }
    }
  }
`
export const GetOrderBookDocument = gql`
  query GetOrderBook($orderBookId: String!, $withOrders: Boolean!, $merged: Boolean!) {
    getOrderBook(orderBookID: $orderBookId, withOrders: $withOrders, merged: $merged) {
      baseUID
      orderBookID
      orders {
        orderBookID
        assetInID
        assetOutID
        amountIn
        amountOut
        price
        type
      }
      priceDisplayMultiplier
      quoteUID
      tickSize
    }
  }
`
export const GetAllOrderBooksDocument = gql`
  query GetAllOrderBooks {
    getAllOrderBooks {
      orderBookID
      baseUID
      quoteUID
      tickSize
      priceDisplayMultiplier
      orders {
        orderBookID
        assetInID
        assetOutID
        amountIn
        amountOut
        price
        type
      }
    }
  }
`
export const OrderBookOpenOrdersDocument = gql`
  query OrderBookOpenOrders($orderBookId: String!) {
    orderBookOpenOrders(orderBookID: $orderBookId) {
      buys {
        price
        size
      }
      sells {
        price
        size
      }
    }
  }
`
export const GetAllOrdersDocument = gql`
  query GetAllOrders($orderBookId: String!, $orderType: OrderType, $orderState: OrderState) {
    getAllOrders(orderBookID: $orderBookId, orderType: $orderType, orderState: $orderState) {
      amountIn
      amountOut
      assetInID
      assetOutID
      createdAt
      inFilled
      orderBookID
      orderID
      outFilled
      price
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      state
      type
      updatedAt
      userUID
    }
  }
`
export const UserOpenOrdersDocument = gql`
  query UserOpenOrders($pagination: Pagination) {
    userOpenOrders(pagination: $pagination) {
      amountIn
      amountOut
      assetInID
      assetOutID
      createdAt
      inFilled
      inverseLeverage
      orderBookID
      orderID
      outFilled
      price
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      state
      type
      updatedAt
      userUID
    }
  }
`
export const UserOrdersDocument = gql`
  query UserOrders($pagination: Pagination) {
    userOrders(pagination: $pagination) {
      amountIn
      amountOut
      assetInID
      assetOutID
      createdAt
      inFilled
      inverseLeverage
      orderBookID
      orderID
      outFilled
      price
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      state
      type
      updatedAt
      userUID
    }
  }
`
export const UserLeveragePositionsDocument = gql`
  query UserLeveragePositions {
    userLeveragePositions {
      borrowed {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
    }
  }
`
export const GetAllPoolsDocument = gql`
  query GetAllPools($pagination: Pagination) {
    getAllPools(pagination: $pagination) {
      uid
      type
      createdAt
      baseAsset {
        assetID
        amount
        sortAmount
      }
      quoteAsset {
        assetID
        amount
        sortAmount
      }
      poolShares {
        assetID
        amount
        sortAmount
      }
      yieldSpaceProp {
        g
        maturityAt
      }
      prodProp {
        swapFee
      }
    }
  }
`
export const GetPoolOrdersSummaryDocument = gql`
  query GetPoolOrdersSummary($orderBookId: String!) {
    getPoolOrdersSummary(orderBookID: $orderBookId) {
      midPrice
      orderBookID
      spread
    }
  }
`
export const SwapPriceDocument = gql`
  query swapPrice($swapPrice: SwapPrice) {
    swapPrice(swapPrice: $swapPrice) {
      pool {
        uid
      }
      tx {
        txID
      }
    }
  }
`
export const TradeHistoryDocument = gql`
  query TradeHistory($pagination: Pagination) {
    tradeHistory(pagination: $pagination) {
      amountIn
      amountOut
      assetInUID
      assetOutUID
      createdAt
      match
      orderBookUID
      poolUID
      priceInToOut
      priceOutToIn
      uid
      userAssetInUID
      userAssetOutUID
    }
  }
`
export const GetUserDocument = gql`
  query GetUser($userUid: String!) {
    getUser(userUID: $userUid) {
      uid
      email
      name
      photoURL
      provider
      providerID
      roles
      registrationDate
      lastLoginDate
    }
  }
`
export const GetAllUsersDocument = gql`
  query GetAllUsers {
    getAllUsers {
      uid
      roles
      name
      email
    }
  }
`
export const UpdateUsersRolesDocument = gql`
  mutation UpdateUsersRoles($updatedRoles: UpdateUserRolesInput) {
    UpdateUsersRoles(updatedRoles: $updatedRoles) {
      uid
      roles
    }
  }
`
export const UserInterestDocument = gql`
  query UserInterest {
    userInterest {
      earned
      owed
    }
  }
`
export const ModuleInterestDocument = gql`
  query ModuleInterest {
    moduleInterest {
      earned
      owed
    }
  }
`
export const ModuleInterestHeldDocument = gql`
  query ModuleInterestHeld {
    moduleInterestHeld {
      amount
      assetID
    }
  }
`
export const UserOrderBookOrdersDocument = gql`
  query UserOrderBookOrders($orderBookId: String!) {
    userOrderBookOrders(orderBookID: $orderBookId) {
      orderID
      orderBookID
      userUID
      createdAt
      updatedAt
      type
      state
      assetInID
      assetOutID
      amountIn
      amountOut
      inFilled
      outFilled
      price
      inverseLeverage
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
    }
  }
`
export const UserBalancesDocument = gql`
  query UserBalances {
    userBalances {
      total {
        assetID
        amount
        sortAmount
      }
      available {
        assetID
        amount
        sortAmount
      }
      locked {
        assetID
        amount
        sortAmount
      }
    }
  }
`
export const OrderBookUpdatedDocument = gql`
  subscription OrderBookUpdated($orderBookId: String!) {
    orderBookUpdated(orderBookID: $orderBookId) {
      sells {
        price
        size
      }
      buys {
        price
        size
      }
    }
  }
`
export const UserOrderChangedDocument = gql`
  subscription UserOrderChanged($orderBookId: String!, $userUid: String!) {
    userOrderChanged(orderBookID: $orderBookId, userUID: $userUid) {
      amountIn
      amountOut
      assetInID
      assetOutID
      createdAt
      inFilled
      inverseLeverage
      orderBookID
      orderID
      outFilled
      price
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      state
      type
      updatedAt
      userUID
    }
  }
`
export const CandlesUpdatedDocument = gql`
  subscription CandlesUpdated($marketId: String!, $resolution: String!) {
    candlesUpdated(marketID: $marketId, resolution: $resolution) {
      close
      high
      low
      open
      time
      volume
    }
  }
`
export const UserBalancesUpdatedDocument = gql`
  subscription UserBalancesUpdated($userUid: String!) {
    userBalancesUpdated(userUID: $userUid) {
      available {
        assetID
        amount
        sortAmount
      }
      locked {
        assetID
        amount
        sortAmount
      }
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action()

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateAsset(
      variables?: CreateAssetMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateAssetMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateAssetMutation>(CreateAssetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateAsset',
        'mutation',
        variables,
      )
    },
    CreateOrder(
      variables?: CreateOrderMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateOrderMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOrderMutation>(CreateOrderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateOrder',
        'mutation',
        variables,
      )
    },
    CancelOrder(
      variables: CancelOrderMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CancelOrderMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelOrderMutation>(CancelOrderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CancelOrder',
        'mutation',
        variables,
      )
    },
    CreatePool(
      variables?: CreatePoolMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreatePoolMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePoolMutation>(CreatePoolDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreatePool',
        'mutation',
        variables,
      )
    },
    AddLiquidityToPool(
      variables?: AddLiquidityToPoolMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddLiquidityToPoolMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddLiquidityToPoolMutation>(AddLiquidityToPoolDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddLiquidityToPool',
        'mutation',
        variables,
      )
    },
    RemoveLiquidityFromPool(
      variables?: RemoveLiquidityFromPoolMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveLiquidityFromPoolMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveLiquidityFromPoolMutation>(RemoveLiquidityFromPoolDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RemoveLiquidityFromPool',
        'mutation',
        variables,
      )
    },
    Mutation(
      variables?: MutationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MutationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MutationMutation>(MutationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Mutation',
        'mutation',
        variables,
      )
    },
    AddBalances(
      variables?: AddBalancesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddBalancesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddBalancesMutation>(AddBalancesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddBalances',
        'mutation',
        variables,
      )
    },
    RemoveBalances(
      variables?: RemoveBalancesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveBalancesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveBalancesMutation>(RemoveBalancesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RemoveBalances',
        'mutation',
        variables,
      )
    },
    Supply(variables: SupplyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SupplyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SupplyMutation>(SupplyDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'Supply',
        'mutation',
        variables,
      )
    },
    Collateralize(
      variables: CollateralizeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CollateralizeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CollateralizeMutation>(CollateralizeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Collateralize',
        'mutation',
        variables,
      )
    },
    Withdraw(
      variables: WithdrawMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<WithdrawMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WithdrawMutation>(WithdrawDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Withdraw',
        'mutation',
        variables,
      )
    },
    Repay(variables: RepayMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RepayMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RepayMutation>(RepayDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'Repay',
        'mutation',
        variables,
      )
    },
    GetAsset(variables: GetAssetQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAssetQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAssetQuery>(GetAssetDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetAsset',
        'query',
        variables,
      )
    },
    GetAllAssets(
      variables: GetAllAssetsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllAssetsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllAssetsQuery>(GetAllAssetsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllAssets',
        'query',
        variables,
      )
    },
    LastTradeDollarAsset(
      variables: LastTradeDollarAssetQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<LastTradeDollarAssetQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LastTradeDollarAssetQuery>(LastTradeDollarAssetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'LastTradeDollarAsset',
        'query',
        variables,
      )
    },
    GetCandles(
      variables: GetCandlesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCandlesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCandlesQuery>(GetCandlesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetCandles',
        'query',
        variables,
      )
    },
    ExploreTxs(
      variables?: ExploreTxsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExploreTxsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExploreTxsQuery>(ExploreTxsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ExploreTxs',
        'query',
        variables,
      )
    },
    GetOrderBook(
      variables: GetOrderBookQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetOrderBookQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOrderBookQuery>(GetOrderBookDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetOrderBook',
        'query',
        variables,
      )
    },
    GetAllOrderBooks(
      variables?: GetAllOrderBooksQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllOrderBooksQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllOrderBooksQuery>(GetAllOrderBooksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllOrderBooks',
        'query',
        variables,
      )
    },
    OrderBookOpenOrders(
      variables: OrderBookOpenOrdersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<OrderBookOpenOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderBookOpenOrdersQuery>(OrderBookOpenOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'OrderBookOpenOrders',
        'query',
        variables,
      )
    },
    GetAllOrders(
      variables: GetAllOrdersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllOrdersQuery>(GetAllOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllOrders',
        'query',
        variables,
      )
    },
    UserOpenOrders(
      variables?: UserOpenOrdersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserOpenOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserOpenOrdersQuery>(UserOpenOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserOpenOrders',
        'query',
        variables,
      )
    },
    UserOrders(
      variables?: UserOrdersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserOrdersQuery>(UserOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserOrders',
        'query',
        variables,
      )
    },
    UserLeveragePositions(
      variables?: UserLeveragePositionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserLeveragePositionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserLeveragePositionsQuery>(UserLeveragePositionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserLeveragePositions',
        'query',
        variables,
      )
    },
    GetAllPools(
      variables?: GetAllPoolsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllPoolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllPoolsQuery>(GetAllPoolsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllPools',
        'query',
        variables,
      )
    },
    GetPoolOrdersSummary(
      variables: GetPoolOrdersSummaryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetPoolOrdersSummaryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPoolOrdersSummaryQuery>(GetPoolOrdersSummaryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetPoolOrdersSummary',
        'query',
        variables,
      )
    },
    swapPrice(
      variables?: SwapPriceQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwapPriceQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SwapPriceQuery>(SwapPriceDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'swapPrice',
        'query',
        variables,
      )
    },
    TradeHistory(
      variables?: TradeHistoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TradeHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TradeHistoryQuery>(TradeHistoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'TradeHistory',
        'query',
        variables,
      )
    },
    GetUser(variables: GetUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUserQuery>(GetUserDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetUser',
        'query',
        variables,
      )
    },
    GetAllUsers(
      variables?: GetAllUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllUsersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllUsersQuery>(GetAllUsersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllUsers',
        'query',
        variables,
      )
    },
    UpdateUsersRoles(
      variables?: UpdateUsersRolesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUsersRolesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUsersRolesMutation>(UpdateUsersRolesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateUsersRoles',
        'mutation',
        variables,
      )
    },
    UserInterest(
      variables?: UserInterestQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserInterestQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserInterestQuery>(UserInterestDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserInterest',
        'query',
        variables,
      )
    },
    ModuleInterest(
      variables?: ModuleInterestQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ModuleInterestQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ModuleInterestQuery>(ModuleInterestDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ModuleInterest',
        'query',
        variables,
      )
    },
    ModuleInterestHeld(
      variables?: ModuleInterestHeldQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ModuleInterestHeldQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ModuleInterestHeldQuery>(ModuleInterestHeldDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ModuleInterestHeld',
        'query',
        variables,
      )
    },
    UserOrderBookOrders(
      variables: UserOrderBookOrdersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserOrderBookOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserOrderBookOrdersQuery>(UserOrderBookOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserOrderBookOrders',
        'query',
        variables,
      )
    },
    UserBalances(
      variables?: UserBalancesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserBalancesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserBalancesQuery>(UserBalancesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserBalances',
        'query',
        variables,
      )
    },
    OrderBookUpdated(
      variables: OrderBookUpdatedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<OrderBookUpdatedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderBookUpdatedSubscription>(OrderBookUpdatedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'OrderBookUpdated',
        'subscription',
        variables,
      )
    },
    UserOrderChanged(
      variables: UserOrderChangedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserOrderChangedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserOrderChangedSubscription>(UserOrderChangedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserOrderChanged',
        'subscription',
        variables,
      )
    },
    CandlesUpdated(
      variables: CandlesUpdatedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CandlesUpdatedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CandlesUpdatedSubscription>(CandlesUpdatedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CandlesUpdated',
        'subscription',
        variables,
      )
    },
    UserBalancesUpdated(
      variables: UserBalancesUpdatedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserBalancesUpdatedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserBalancesUpdatedSubscription>(UserBalancesUpdatedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserBalancesUpdated',
        'subscription',
        variables,
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
