import type { ResolutionString } from '@/tradingview/charting_library'

export const PRICE_DECIMALS = 3
export const AMOUNT_DECIMALS = 2
export const MIN_PRICE = 50
export const MAX_PRICE = 100
export const MAX_AMOUNT = 1000000
export const MAX_AMOUNT_ADDING_BALANCE = 10000000
export const PASSWORD_VALIDATION_REGEX = RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)
export const TOAST_TIMEOUT = 3000
export enum TIME_DURATION {
  DAY = 1000 * 60 * 60 * 24,
  HOUR = 1000 * 60 * 60,
  MIN = 1000 * 60,
}
export enum CandleResolution {
  ONE_MINUTE = '1MIN',
  FIVE_MINUTES = '5MINS',
  FIFTEEN_MINUTES = '15MINS',
  THIRTY_MINUTES = '30MINS',
  ONE_HOUR = '1HOUR',
  FOUR_HOURS = '4HOURS',
  ONE_DAY = '1DAY',
}
export const timeUnits = {
  year: 31536000000,
  month: 2628000000,
  week: 604800000,
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 1000,
} satisfies Partial<Record<Intl.RelativeTimeFormatUnit, number>>

export const RESOLUTION_MAP = {
  '1': CandleResolution.ONE_MINUTE,
  '5': CandleResolution.FIVE_MINUTES,
  '15': CandleResolution.FIFTEEN_MINUTES,
  '30': CandleResolution.THIRTY_MINUTES,
  '60': CandleResolution.ONE_HOUR,
  '240': CandleResolution.FOUR_HOURS,
  '1D': CandleResolution.ONE_DAY,
} as Record<ResolutionString, CandleResolution>

export const RESOLUTION_MAP_TV_GRAPHQL = {
  '1': '1m',
  '60': '1h',
  '1D': '1D',
} as Record<ResolutionString, string>

export const RESOLUTION_MAP_TV_GRAPHQL_SUBSCRIPTION = {
  '1': '1m',
  '5': '5m',
  '15': '15m',
  '30': '30m',
  '60': '1h',
  '240': '4h',
  '1D': '1D',
  '1W': '1W',
  '1M': '1M',
} as Record<ResolutionString, number>

export const RESOLUTION_CHART_CONFIGS = {
  '1': { defaultRange: timeUnits.hour },
  '5': { defaultRange: 5 * timeUnits.hour },
  '15': { defaultRange: 15 * timeUnits.hour },
  '30': { defaultRange: 30 * timeUnits.hour },
  '60': { defaultRange: 3 * timeUnits.day },
  '240': { defaultRange: 12 * timeUnits.day },
  '1D': { defaultRange: 2 * timeUnits.month },
} as Record<ResolutionString, { defaultRange: number }>
export const DEFAULT_RESOLUTION = '1'
export enum LocalStorageKey {
  // Onboarding / Accounts
  EvmAddress = 'dydx.EvmAddress',
  DydxAddress = 'dydx.DydxAddress',
  OnboardingSelectedWalletType = 'dydx.OnboardingSelectedWalletType',
  WalletConnectionType = 'dydx.WalletConnectionType',
  OnboardingHasAcknowledgedTerms = 'dydx.OnboardingHasAcknowledgedTerms',
  EvmDerivedAddresses = 'dydx.EvmDerivedAddresses',

  // Notifications
  Notifications = 'dydx.Notifications',
  NotificationsLastUpdated = 'dydx.NotificationsLastUpdated',
  PushNotificationsEnabled = 'dydx.PushNotificationsEnabled',
  PushNotificationsLastUpdated = 'dydx.PushNotificationsLastUpdated',
  TransferNotifications = 'dydx.TransferNotifications',
  NotificationPreferences = 'dydx.NotificationPreferences',

  // UI State
  LastViewedMarket = 'dydx.LastViewedMarket',
  SelectedLocale = 'dydx.SelectedLocale',
  SelectedNetwork = 'dydx.SelectedNetwork',
  SelectedTheme = 'dydx.SelectedTheme',
  SelectedColorMode = 'dydx.SelectedColorMode',
  SelectedTradeLayout = 'dydx.SelectedTradeLayout',
  TradingViewChartConfig = 'dydx.TradingViewChartConfig',
  HasSeenLaunchIncentives = 'dydx.HasSeenLaunchIncentives',
  DefaultToAllMarketsInPositionsOrdersFills = 'dydx.DefaultToAllMarketsInPositionsOrdersFills',
}

export const LOCAL_STORAGE_VERSIONS = {
  [LocalStorageKey.EvmDerivedAddresses]: 'v2',
  [LocalStorageKey.NotificationPreferences]: 'v1',
  [LocalStorageKey.TransferNotifications]: 'v1',
  [LocalStorageKey.Notifications]: 'v1',
  // TODO: version all localStorage keys
}
