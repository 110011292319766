import React, { useState, useEffect } from 'react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import { Connection, PublicKey } from '@solana/web3.js'
import { formatNumber } from '@/utils/numberUtils'
import { PRICE_DECIMALS } from '@/utils/constants'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { MdOutlineContentCopy } from 'react-icons/md'
import { AiOutlineDisconnect } from 'react-icons/ai'

function SolanaButton() {
  const { publicKey, wallet } = useWallet()
  const [balance, setBalance] = useState<number | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  useEffect(() => {
    const fetchBalance = async () => {
      if (publicKey) {
        const connection = new Connection(
          'https://mainnet.helius-rpc.com/?api-key=e218af64-48b5-4a1a-b52a-5728fbe3f4c4',
        )
        const balance = await connection.getBalance(new PublicKey(publicKey))
        setBalance(balance / 1e9) // Convert lamports to SOL
      }
    }

    fetchBalance().finally()
  }, [publicKey])

  const onCopyAddress = () => {
    navigator.clipboard.writeText(publicKey?.toBase58() || '').then(() => {
      console.log('publicKey copied to clipboard')
      setDropdownOpen(false) // Close dropdown menu
    })
  }

  const onDisconnect = () => {
    wallet?.adapter
      .disconnect()
      .then(() => {
        console.log('wallet disconnected')
        setDropdownOpen(false) // Close dropdown menu
      })
      .catch((error) => {
        console.error('wallet disconnect error', error)
      })
  }

  return (
    <div className='App flex flex-col gap-10 justify-center items-center'>
      {!publicKey && (
        <div className={'w-full flex flex-row justify-center items-center gap-2'}>
          {!wallet?.adapter.connected && (
            <img alt='ethereumIcon' className={'w-[32px] h-[32px] p-0 m-0'} src={'/assets/images/wallet/solana.svg'} />
          )}
          <WalletMultiButton className='wallet-multi-button'>Connect Wallet</WalletMultiButton>
        </div>
      )}
      {publicKey && (
        <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild className={'w-full bg-panel p-0'}>
            <div>
              <div>
                <div
                  className={
                    'solana-button flex flex-row gap-0.5 items-center text-white border border-gray-800 rounded-3xl p-1 bg-black/50 font-semibold text-base wallet-font cursor-pointer '
                  }
                >
                  <div>
                    <img
                      alt='solanaIcon'
                      className={'w-[24px] h-[24px] p-0 m-0'}
                      src={'/assets/images/wallet/solana.svg'}
                    />
                  </div>
                  <div>{balance !== null ? `${formatNumber(balance, PRICE_DECIMALS)} SOL` : 'Loading...'}</div>

                  <div
                    className={
                      'flex flex-row ms-2 gap-0.5 border border-black-black20 rounded-3xl bg-black-black20 p-0.5 pl-1.5 pr-3 justify-start items-center'
                    }
                  >
                    <div
                      className={
                        'w-[20px] h-[20px] p-0 mr-1 rounded-full bg-gradient-to-r from-purple-400 to-purple-600'
                      }
                    ></div>
                    <div
                      className={'text-gray-400'}
                    >{`${publicKey.toBase58().slice(0, 4)}...${publicKey.toBase58().slice(-7)}`}</div>
                  </div>
                </div>
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-full'>
            <div
              className={'hover:bg-dark-darker cursor-pointer flex flex-row gap-2 justify-start items-center'}
              onClick={() => onCopyAddress()}
            >
              <MdOutlineContentCopy size={14} className={'cursor-pointer text-white-dark'} />
              <div className={'text-sm text-white-dark'}>Copy Address</div>
            </div>
            <div
              className={'hover:bg-dark-darker cursor-pointer flex flex-row gap-2 justify-start items-center'}
              onClick={() => onDisconnect()}
            >
              <AiOutlineDisconnect size={14} className={'cursor-pointer text-white-dark'} />
              <div className={'text-sm text-white-dark'}>Disconnect</div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}

export default SolanaButton
