import { useAccount } from 'wagmi'
import React from 'react'

export const EthereumButton = () => {
  const { isConnected } = useAccount()

  return (
    <div className={'w-full flex flex-row justify-center items-center gap-2'}>
      {!isConnected && (
        <img alt='ethereumIcon' className={'w-[32px] h-[32px] p-0 m-0'} src={'/assets/images/wallet/ethereum.svg'} />
      )}
      <div>
        <w3m-button />
      </div>
    </div>
  )
}
