import { useQuery } from '@tanstack/react-query'
import { getSdk, UserBalances } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'

export const useUserBalances = () => {
  const user = useFirebaseAuth()

  const { isLoading, data } = useQuery({
    queryKey: ['USER_BALANCES'],
    enabled: !!user,
    queryFn: async () =>
      getSdk(graphQLClient)
        .UserBalances()
        .then((result) => result.userBalances as UserBalances)
        .catch((err) => {
          const message = err?.message ?? 'Error on UserBalances'
          createNotification('Error on UserBalances', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
  })

  return {
    isLoading,
    balances: data ? data : { available: [], locked: [] },
  }
}
