import { BsFillLightningChargeFill } from 'react-icons/bs'
import { FaPiggyBank } from 'react-icons/fa6'
import { IRootState } from '@/store'
import { IoMdSwap } from 'react-icons/io'
import { MdContentPasteSearch, MdOutlineAdminPanelSettings, MdOutlineSwapVert } from 'react-icons/md'
import { MenuIcon } from '@/components/MenuIcon'
import { NavLink } from 'react-router-dom'
import { NotificationDrawer } from '@/components/notifications/NotificationDrawer'
import { ProfileMenu } from '@/components/profile/ProfileMenu'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { clearNotifications, deleteNotification } from '@/store/notificationSlice'
import { sortBy } from 'lodash'
import { WalletModalButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import SolanaButton from '@/components/wallet/SolanaButton'
import { WalletButton } from '@/components/wallet/walletButton'

interface HeaderProps {
  setIsMobileMenuOpen: (isActive: boolean) => void
  isMobileMenuOpen: boolean
}

const Header = ({ setIsMobileMenuOpen, isMobileMenuOpen }: HeaderProps) => {
  const notifications = useSelector((state: IRootState) => state.notification.notifications)
  const themeConfig = useSelector((state: IRootState) => state.themeConfig)
  const { isTablet } = useBreakpoints()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // Sort notifications by timestamp, most recent first, using lodash
  const sortedNotifications = sortBy(notifications, 'timestamp').reverse()

  const clearAllNotificationsFromStore = () => {
    dispatch(clearNotifications())
  }

  const deleteNotificationFromStore = (id: string) => {
    dispatch(deleteNotification(id))
  }

  const menuLayoutMobile = () => {
    return (
      <>
        {/*MENU STARTS HERE*/}
        <div>
          <MenuIcon
            onClick={(isActive) => {
              setIsMobileMenuOpen(isActive)
            }}
            isMobileMenuOpen={isMobileMenuOpen}
          />
        </div>
      </>
    )
  }

  const menuLayout = () => {
    return (
      <div className={'flex'}>
        {/*MENU STARTS HERE*/}
        <div className='horizontal-menu py-1 hidden font-semibold px-6 !shadow-none dark:border-[#191e3a] text-black dark:text-white-dark gap-1'>
          <NavLink to='/pool' className='nav-link'>
            <div className='menu nav-item relative flex items-center'>
              <MdOutlineSwapVert size={24} className='mr-2' />
              <span className='px-1'>{t('Pool')}</span>
            </div>
          </NavLink>
          <NavLink to='/trade' className='nav-link'>
            <div className='menu nav-item relative flex items-center'>
              <IoMdSwap size={24} className='mr-2' />
              <span className='px-1'>{t('Trade')}</span>
            </div>
          </NavLink>
          <NavLink to='/yield' className='nav-link'>
            <div className='menu nav-item relative flex items-center'>
              <BsFillLightningChargeFill size={20} className='mr-2' />
              <span className='px-1'>{t('Yield')}</span>
            </div>
          </NavLink>
          <NavLink to='/portfolio' className='nav-link'>
            <div className='menu nav-item relative flex items-center'>
              <FaPiggyBank size={24} className='mr-2' />
              <span className='px-1'>{t('Portfolio')}</span>
            </div>
          </NavLink>
          <NavLink to='/explorer' className='nav-link'>
            <div className='menu nav-item relative flex items-center'>
              <MdContentPasteSearch size={24} className='mr-2' />
              <span className='px-1'>{t('Explorer')}</span>
            </div>
          </NavLink>
          <NavLink to='/administration' className='nav-link'>
            <div className='menu nav-item relative flex items-center'>
              <MdOutlineAdminPanelSettings size={24} className='mr-2' />
              <span className='px-1'>{t('Administration')}</span>
            </div>
          </NavLink>
          <WalletButton />
          <NotificationDrawer
            notifications={sortedNotifications}
            clearAllNotifications={clearAllNotificationsFromStore}
            deleteNotification={deleteNotificationFromStore}
          />
        </div>
        <ProfileMenu />
      </div>
    )
  }

  return (
    <header className={`z-40 ${themeConfig.semidark && themeConfig.menu === 'horizontal' ? 'dark' : ''}`}>
      <div className='shadow-sm bg-panel'>
        <div className='relative flex w-full items-center px-5'>
          <div className='w-full justify-between flex items-center '>
            <NavLink to='/trade' className='nav-link'>
              <h1 className='ml-0 font-extrabold text-lg'>DORA</h1>
            </NavLink>

            <div className='mr-0'>{isTablet ? menuLayoutMobile() : menuLayout()}</div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
