import React, { FormEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { removeLettersForInput } from '@/utils/inputUtils'
import { useMutation } from '@tanstack/react-query'
import { AssetType, AssetUsage, BondInput, getSdk } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { removeFormatNumber } from '@/utils/numberUtils'

const validationSchema = z.object({
  couponStartDate: z.string().optional(),
  finalCouponDate: z.string().optional(),
  isin: z.string().optional(),
  issueDate: z.string().optional(),
  issuer: z.string().optional(),
  maturityDate: z.string().optional(),
  name: z.string().optional(),
  numberOfPaymentsPerYear: z.string().optional(),
  yield: z.string().optional(),
  decimals: z.string(),
  description: z.string().min(1, 'Please insert a description'),
  symbol: z.string().min(1, 'Please insert a symbol'),
  type: z.enum([AssetType.Bond, AssetType.Currency, AssetType.PoolShare]),
  usage: z.enum([
    AssetUsage.Trade,
    AssetUsage.DirectBorrow,
    AssetUsage.VirtualBorrow,
    AssetUsage.PoolShare,
    AssetUsage.Onboard,
  ]),
  collateralWeight: z.string().min(1, 'Please insert a collateral weight'),
  liquidationThreshold: z.string().min(1, 'Please insert a liquidation threshold'),
  maxUtilization: z.string().min(1, 'Please insert a max utilization'),
  maxSupply: z.string().min(1, 'Please insert a max supply'),
})

type ValidationSchema = z.infer<typeof validationSchema>

export const CreateAssetForm = () => {
  const [selectedType, setSelectedType] = useState<string>()
  const [selectedUsage, setSelectedUsage] = useState<string>()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  })

  const handleInputChange = (e: FormEvent<HTMLInputElement>, field: keyof ValidationSchema) => {
    const inputValue = e.currentTarget.value
    const sanitizedInput = removeLettersForInput(inputValue)
    setValue(field, sanitizedInput)
  }

  const createAssetMutation = useMutation({
    mutationFn: ({
      decimals,
      description,
      symbol,
      type,
      usage,
      collateralWeight,
      liquidationThreshold,
      maxUtilization,
      maxSupply,
      bond,
    }: {
      decimals: number
      description: string
      symbol: string
      type: AssetType
      usage: AssetUsage[]
      collateralWeight: string
      liquidationThreshold: string
      maxUtilization: string
      maxSupply: string
      bond: BondInput | null
    }) =>
      getSdk(graphQLClient)
        .CreateAsset({
          newAsset: {
            decimals: decimals,
            description: description,
            symbol: symbol,
            type: type,
            usage: usage,
            collateralWeight: collateralWeight,
            liquidationThreshold: liquidationThreshold,
            maxUtilization: maxUtilization,
            maxSupply: maxSupply,
            Bond: bond,
          },
        })
        .then(() => {
          createNotification('Create Asset ', { symbol: symbol }, NotificationType.CREATE_ASSET)
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on CreateAsset'
          createNotification('Error on CreateAsset', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ASSETS'] }).finally()
    },
  })

  const createAsset = async (data: FixMe) => {
    const payload = {
      decimals: Number(removeFormatNumber(data.decimals)),
      description: data.description,
      symbol: data.symbol,
      type: data.type,
      usage: [data.usage],
      collateralWeight: removeFormatNumber(data.collateralWeight),
      liquidationThreshold: removeFormatNumber(data.liquidationThreshold),
      maxUtilization: removeFormatNumber(data.maxUtilization),
      maxSupply: removeFormatNumber(data.maxSupply),
      bond:
        data.type === AssetType.Bond
          ? {
              issuer: data.issuer,
              couponStartDate: data.couponStartDate,
              isin: data.isin,
              finalCouponDate: data.finalCouponDate,
              maturityDate: data.maturityDate,
              issueDate: data.issueDate,
              name: data.name,
              numberOfPaymentsPerYear: Number(removeFormatNumber(data.numberOfPaymentsPerYear)),
              yield: Number(removeFormatNumber(data.yield)),
            }
          : null,
    }

    console.log('createAsset', payload)
    createAssetMutation.mutate(payload)
  }

  return (
    <form onSubmit={handleSubmit(createAsset)} className='w-full'>
      <div className='w-full h-full flex flex-row gap-2 justify-start items-center'>
        <div className='w-full max-w-md panel dark:bg-card flex flex-col flex-grow h-full'>
          <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>Create Asset</h1>

          {/*FIRST ROW*/}
          <div className='flex flex-row justify-between gap-2'>
            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Collateral Weight</div>
                  <input
                    id={'collateralWeight'}
                    onInput={(e) => handleInputChange(e, 'collateralWeight')}
                    {...register('collateralWeight', { required: true })}
                    className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.symbol ? errors.symbol.message : '\u00a0'}
              </span>
            </div>

            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Decimals</div>
                  <input
                    id={'decimals'}
                    {...register('decimals', { required: true })}
                    onInput={(e) => handleInputChange(e, 'decimals')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.symbol ? errors.symbol.message : '\u00a0'}
              </span>
            </div>
          </div>

          <div className='w-full flex flex-row justify-between gap-2'>
            <div className='w-full flex flex-col'>
              <div className='relative flex flex-row w-full justify-between'>
                <div className='w-full flex flex-row justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Description</div>
                  <textarea
                    id={'description'}
                    {...register('description', { required: true })}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.description ? errors.description.message : '\u00a0'}
              </span>
            </div>
          </div>

          <div className='flex flex-row justify-between gap-2'>
            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Liquidation Threshold</div>
                  <input
                    id={'liquidationThreshold'}
                    {...register('liquidationThreshold', { required: true })}
                    onInput={(e) => handleInputChange(e, 'liquidationThreshold')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.symbol ? errors.symbol.message : '\u00a0'}
              </span>
            </div>

            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Max Supply</div>
                  <input
                    id={'maxSupply'}
                    {...register('maxSupply', { required: true })}
                    onInput={(e) => handleInputChange(e, 'maxSupply')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.symbol ? errors.symbol.message : '\u00a0'}
              </span>
            </div>
          </div>

          <div className='flex flex-row justify-between gap-2'>
            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Max Utilization</div>
                  <input
                    id={'maxUtilization'}
                    {...register('maxUtilization', { required: true })}
                    onInput={(e) => handleInputChange(e, 'maxUtilization')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.symbol ? errors.symbol.message : '\u00a0'}
              </span>
            </div>

            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Symbol</div>
                  <input
                    id={'symbol'}
                    {...register('symbol', { required: true })}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.symbol ? errors.symbol.message : '\u00a0'}
              </span>
            </div>
          </div>

          <div className='flex flex-row justify-between gap-2'>
            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full justify-between'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Type</div>
                  <select
                    {...register('type', { required: true })}
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                    className='relative rounded-sm shadow-sm appearance-none form-input py-1 peer placeholder:tracking-widest border valid:border-dark text-right outline-none focus:ring-0 focus:ring-opacity-0'
                  >
                    {Object.values(AssetType).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='relative flex flex-row w-full justify-between'>
                <div className='w-full flex justify-between items-center top-0 gap-2'>
                  <div className='py-1 whitespace-nowrap text-left'>Usage</div>
                  <select
                    {...register('usage', { required: true })}
                    value={selectedUsage}
                    onChange={(e) => setSelectedUsage(e.target.value)}
                    className='relative rounded-sm shadow-sm appearance-none form-input py-1 peer placeholder:tracking-widest border valid:border-dark text-right outline-none focus:ring-0 focus:ring-opacity-0'
                  >
                    {Object.values(AssetUsage).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-row justify-between gap-2'>
            <button
              type='submit'
              className='btn mt-2 bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
            >
              Create Asset
            </button>
          </div>
        </div>

        {/*//BOND*/}
        {selectedType === AssetType.Bond && (
          <div className='panel dark:bg-card flex flex-col h-full'>
            <div className='flex flex-row justify-between gap-2'>
              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Name</div>
                    <input
                      id={'name'}
                      {...register('name', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>

              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Issuer</div>
                    <input
                      id={'issuer'}
                      {...register('issuer', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>
            </div>

            <div className='flex flex-row justify-between gap-2'>
              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>ISIN</div>
                    <input
                      id={'isin'}
                      {...register('isin', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>

              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Yield</div>
                    <input
                      id={'yield'}
                      onInput={(e) => handleInputChange(e, 'yield')}
                      {...register('yield', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>
            </div>

            <div className='flex flex-row justify-between gap-2'>
              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Coupon Start Date</div>
                    <input
                      id={'couponStartDate'}
                      type='date'
                      {...register('couponStartDate', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>

              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Coupon Final Date</div>
                    <input
                      id={'finalCouponDate'}
                      type='date'
                      {...register('finalCouponDate', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>
            </div>

            <div className='flex flex-row justify-between gap-2'>
              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Issue date</div>
                    <input
                      id={'issueDate'}
                      type={'date'}
                      {...register('issueDate', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>

              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Maturity Date</div>
                    <input
                      id={'maturityDate'}
                      type={'date'}
                      {...register('maturityDate', { required: false })}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>
            </div>

            <div className='flex flex-row justify-between gap-2'>
              <div className='flex flex-col'>
                <div className='relative flex flex-row w-full'>
                  <div className='w-full flex justify-between items-center top-0 gap-2'>
                    <div className='py-1 whitespace-nowrap text-left'>Number of payments per year</div>
                    <input
                      id={'numberOfPaymentsPerYear'}
                      {...register('numberOfPaymentsPerYear', { required: false })}
                      onInput={(e) => handleInputChange(e, 'numberOfPaymentsPerYear')}
                      className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    />
                  </div>
                </div>
                <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                  {errors.symbol ? errors.symbol.message : '\u00a0'}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}
