import { Button } from '@/components/ui/button'
import { Link, NavLink } from 'react-router-dom'
import { SlEnvolope, SlLock, SlLogout, SlSettings, SlUser } from 'react-icons/sl'
import { copyToken } from '@/utils/tokenUtils'
import { getAuth, signOut } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import { useUser } from '@/hooks/useUser'

interface MobileMenuProps {
  setIsMobileMenuOpen: (isActive: boolean) => void
}

export const MobileMenu = ({ setIsMobileMenuOpen }: MobileMenuProps) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const photo = user?.photoURL ?? '/assets/images/user-profile.png'

  return (
    <div className={'px-5'}>
      <div className='py-32'>
        <NavLink
          to='/trade'
          className='nav-link'
          onClick={() => {
            setIsMobileMenuOpen(false)
          }}
        >
          <div className='menu nav-item relative flex items-center text-white text-4xl py-2'>
            <span className='px-1'>{t('Trade')}</span>
          </div>
        </NavLink>
        <NavLink
          to='/assets'
          className='nav-link'
          onClick={() => {
            setIsMobileMenuOpen(false)
          }}
        >
          <div className='menu nav-item relative flex items-center text-white text-4xl py-2'>
            <span className='px-1'>{t('My Assets')}</span>
          </div>
        </NavLink>
        <NavLink
          to='/explorer'
          className='nav-link'
          onClick={() => {
            setIsMobileMenuOpen(false)
          }}
        >
          <div className='menu nav-item relative flex items-center text-white text-4xl py-2'>
            <span className='px-1'>{t('Explorer')}</span>
          </div>
        </NavLink>
        <NavLink
          to='/administration'
          className='nav-link'
          onClick={() => {
            setIsMobileMenuOpen(false)
          }}
        >
          <div className='menu nav-item relative flex items-center text-white text-4xl py-2'>
            <span className='px-1'>{t('Admin')}</span>
          </div>
        </NavLink>
      </div>

      <div className='text-dark dark:text-white-dark !py-0 font-semibold dark:text-white-light/90'>
        <div>
          <div className='flex items-center'>
            <img className='rounded-md w-10 h-10 object-cover' src={photo} alt='userProfile' />
            <div className='ltr:pl-4 rtl:pr-4 truncate'>
              <h4 className='text-base'>
                {user?.name ?? 'User'}
                <span className='text-xs bg-success-light rounded text-success px-1 ltr:ml-2 rtl:ml-2'>Pro</span>
              </h4>
              <button
                type='button'
                className='text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white'
              >
                {user?.email}
              </button>
            </div>
          </div>
        </div>

        <div className='w-full flex justify-between mt-8'>
          <div className='w-full'>
            <Link to='/user/profile' className='flex items-center dark:hover:text-white'>
              <SlUser size={24} className='mr-2 text-gray-400' />
              Profile
            </Link>
          </div>
          <div className='w-full'>
            <Link to='/user/settings' className='flex items-center dark:hover:text-white'>
              <SlSettings size={24} className='mr-2 text-gray-400' />
              Account Settings
            </Link>
          </div>
        </div>

        <div className='w-full flex justify-between mt-4'>
          <div className='w-full'>
            <Link to='/apps/mailbox' className='flex items-center dark:hover:text-white'>
              <SlEnvolope size={24} className='mr-2 text-gray-400' />
              Inbox
            </Link>
          </div>
          <div className='w-full'>
            <Link to='/auth/boxed-lockscreen' className='flex items-center dark:hover:text-white'>
              <SlLock size={24} className='mr-2 text-gray-400' />
              Lock Screen
            </Link>
          </div>
        </div>

        <div className='w-full mt-4'>
          <div className='border-t border-white-light dark:border-white-light/10'>
            <Link
              to='/auth/sign-in'
              className='flex items-center text-danger'
              role='button'
              onClick={() => signOut(getAuth())}
            >
              <SlLogout size={24} className='pr-0.5 mr-2 text-danger' />
              Sign Out
            </Link>
          </div>
        </div>

        <div className='p-2 mt-8'>
          <Button
            className='w-full btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-black border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
            onClick={copyToken}
          >
            Copy token
          </Button>
        </div>
      </div>
    </div>
  )
}
