import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryCache, QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import axios from 'axios'
import { getAuth } from 'firebase/auth'
import * as Sentry from '@sentry/react'

export function sleep(ms = 0): Promise<void> {
  console.warn('Kindly remember to remove `sleep`')
  console.warn(`Sleeping for ${ms}ms`)
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
})

apiClient.interceptors.response.use(
  async (response) => {
    if (import.meta.env.MODE === 'development') {
      await sleep(1000)
    }
    return response
  },
  (error) => {
    const response = error.response
    const data = response?.data
    if (data) {
      Sentry.captureMessage(`Axios apiClient response with data: ${error.message}`, {
        extra: {
          error: error,
          errorMessage: error.message,
          dataMessage: data.message,
          data: data,
        },
      })
      throw new Error(data.message)
    } else {
      Sentry.captureMessage(`Axios apiClient response: ${error.message}`, {
        extra: {
          error: error,
          errorMessage: error.message,
        },
      })
      throw new Error(error.message)
    }
  },
)

apiClient.interceptors.request.use(
  async (request) => {
    console.log('REQUEST:::::::', request)
    console.log('REQUEST::::Payload:::', request.params)
    const newFirebaseToken = await getAuth().currentUser?.getIdToken()
    const token = newFirebaseToken || localStorage.getItem('firebaseToken')
    if (token) {
      request.headers.Authorization = token
    }
    return request
  },
  (error) => {
    Promise.reject(error).then((r) => {
      console.log('Error:', r)
      Sentry.captureMessage(`Axios apiClient interceptors request: ${error.message}`, {
        extra: {
          error: error,
          errorMessage: error.message,
        },
      })
    })
  },
)

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        Sentry.captureMessage(`QueryClient: ${error.message}`, {
          extra: {
            error: error,
            errorMessage: error.message,
            query: query,
          },
        })
      }
    },
  }),

  defaultOptions: {
    queries: {
      retry: 3,
      // staleTime: 0,
      // gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

persistQueryClient({
  dehydrateOptions: {
    shouldDehydrateQuery: (query) => {
      return query.queryKey[0] == 'CHART'
    },
  },
  queryClient,
  persister,
})

export { apiClient, persister, queryClient }
