import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import React, { useState } from 'react'
import { AiOutlineClear } from 'react-icons/ai'
import { BiCollapseHorizontal, BiExpandHorizontal } from 'react-icons/bi'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { LuArrowRightSquare } from 'react-icons/lu'
import { NotificationItem } from '@/utils/notificationUtils'
import { NotificationItemComponent } from '@/components/notifications/NotificationItemComponent'

interface NotificationDrawerProps {
  notifications: NotificationItem[]
  deleteNotification: (id: string) => void
  clearAllNotifications: () => void
}

export const NotificationDrawer = ({
  notifications,
  deleteNotification,
  clearAllNotifications,
}: NotificationDrawerProps) => {
  const [expanded, setExpanded] = useState(false)

  const onNotificationClose = (notification: NotificationItem) => {
    console.log('onNotificationClose', notification)
    deleteNotification(notification.id)
  }

  const onExpandClicked = () => {
    setExpanded(!expanded)
  }

  return (
    <Drawer direction='right' onClose={() => setExpanded(false)}>
      <DrawerTrigger asChild>
        <div className='h-full relative flex items-center cursor-pointer'>
          <div className='relative px-2 hover:text-white hover:bg-dark-darker rounded'>
            <IoMdNotificationsOutline
              size={14}
              className='w-full h-full mr-2 text-gray-400'
              aria-label='Notifications'
            />
            {notifications.length > 0 && (
              <div
                className='absolute bottom-0 right-1 transform translate-x-1/2 translate-y-1/2 min-h-3 min-w-3 bg-danger rounded-full flex justify-center items-center px-1'
                aria-label={`You have ${notifications.length} notifications`}
              >
                <div className='text-white text-xs flex items-center justify-center'>{notifications.length}</div>
              </div>
            )}
          </div>
        </div>
      </DrawerTrigger>
      <DrawerContent
        className={`h-screen top-0 right-0 left-auto mt-0 ${expanded ? 'w-full' : 'w-[350px]'} rounded-none mx-auto px-4 overflow-y-scroll overflow-x-hidden`}
      >
        <DrawerHeader>
          <DrawerTitle className={'flex items-center'}>
            <DrawerClose className={'text-lg font-semibold text-center'}>
              <LuArrowRightSquare size={18} color={'text-white-dark'} />
            </DrawerClose>
            <div className={'w-full text-center'}>System notifications</div>
          </DrawerTitle>
          <DrawerDescription className={'w-full text-center'}>
            <div>Here you can see all the system notifications.</div>

            <div className={'flex justify-between'}>
              <div
                className={'w-fit flex flex-row text-xs text-danger text-end mt-4 cursor-pointer items-center gap-2'}
                onClick={onExpandClicked}
              >
                {expanded ? <BiCollapseHorizontal /> : <BiExpandHorizontal />}
                {expanded ? 'Collapse' : 'Expand'}
              </div>

              <div
                className={'w-fit flex flex-row text-xs text-danger text-end mt-4 cursor-pointer items-center gap-2'}
                onClick={clearAllNotifications}
              >
                <AiOutlineClear />
                Clear All
              </div>
            </div>
          </DrawerDescription>
        </DrawerHeader>

        {notifications.length > 0 &&
          notifications.map((notification, index) => (
            <NotificationItemComponent
              key={'notification_item' + index}
              item={notification}
              onNotificationClose={() => onNotificationClose(notification)}
            />
          ))}
        {notifications.length === 0 && (
          <div className={'w-full flex text-xs mt-4 justify-center'}>No notifications</div>
        )}
      </DrawerContent>
    </Drawer>
  )
}
