import React, { useState, useEffect } from 'react'
import SolanaButton from '@/components/wallet/SolanaButton'
import { EthereumButton } from '@/components/wallet/EthereumButton'

export const WalletButton = () => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    console.log('handleClose')
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    if (open) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  return (
    <div>
      {open && (
        <div className={'relative'} onClick={handleClose}>
          <div className={'fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
            <div
              id={'wallet-modal'}
              className={
                'w-1/4 h-1/4 bg-panel flex flex-col gap-2 justify-center items-center border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
              }
              onClick={(e) => e.stopPropagation()}
            >
              <div className={'text-white text-xl font-semibold mb-6'}>Wallet</div>
              <div className={'flex flex-col text-center'}>
                <EthereumButton />
              </div>
              <div className={'flex flex-col text-center mt-4'}>
                <SolanaButton />
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        type='button'
        onClick={() => setOpen(true)}
        className='btn bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
      >
        Wallet
      </button>
    </div>
  )
}
