import { createContext, useContext, useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import { queryClient } from '@/services/api'
import * as Sentry from '@sentry/react'

const auth = getAuth()

type FirebaseUser = User | null | undefined
type ContextState = { user: FirebaseUser }

const FirebaseAuthContext = createContext<ContextState | undefined>(undefined)

const FirebaseAuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<FirebaseUser>(undefined)
  const value = { user }

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        user.getIdToken(true).then((token) => {
          localStorage.setItem('sentry_user', user.email ?? user.uid)
          Sentry.setContext('user', {
            name: user.displayName,
            email: user.email,
            uid: user.uid,
          })
          localStorage.setItem('firebaseToken', token)
        })
      } else {
        setUser(null)
        queryClient.clear()
        localStorage.removeItem('firebaseToken')
      }
    })
    return () => unSubscribe()
  }, [])
  if (user === undefined) return null

  return <FirebaseAuthContext.Provider value={value}>{children}</FirebaseAuthContext.Provider>
}

function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext)
  if (context === undefined) {
    throw new Error('useFirebaseAuth must be used within a FirebaseAuthProvider')
  }
  return context.user
}

export { FirebaseAuthProvider, useFirebaseAuth }
