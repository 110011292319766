import { gql } from 'graphql-request'

export const USER_BALANCES = gql`
  subscription UserBalancesUpdated($userUid: String!) {
    userBalancesUpdated(userUID: $userUid) {
      available {
        assetID
        amount
        sortAmount
      }
      locked {
        assetID
        amount
        sortAmount
      }
    }
  }
`
