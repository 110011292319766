import { ColumnDef } from '@tanstack/react-table'
import { User, UserRole } from '@/graphql/generated/graphql-request'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { createNotification, NotificationType } from '@/utils/notificationUtils'

export const tableColumnsUserRoles: ColumnDef<User>[] = [
  {
    accessorKey: 'uid',
    header: () => <div className='text-xs text-left text-white'>UID</div>,
    cell: ({ row }) => {
      const uid = row.original.uid
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(uid)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `UID ${uid} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {uid}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'name',
    header: () => <div className='text-xs text-left text-white'>Name</div>,
    cell: ({ row }) => {
      const name = row.original.name ?? ''
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(name)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Name ${name} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {name}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'email',
    header: () => <div className='text-xs text-left text-white'>Email</div>,
    cell: ({ row }) => {
      const email = row.original.email
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(email)
              .finally(() =>
                createNotification(
                  'Copied ',
                  `Email ${email} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {email}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'roles',
    header: () => <div className='text-xs text-left text-white'>Roles</div>,
    cell: ({ row, table }) => {
      const roles = row.original.roles
      const uid = row.original.uid
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className={'border-0 bg-card hover:bg-card'}>
            <button
              type='button'
              className='w-[150px] bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
            >
              {roles.length > 0 && roles[roles.length - 1]}
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-full'>
            <DropdownMenuLabel>
              <div className={'w-full ps-8 text-base text-primary'}>Select Role</div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup className={'w-full'}>
              {Object.values(UserRole)?.map((role) => (
                <DropdownMenuRadioItem
                  key={uid}
                  value={role}
                  onClick={() => {
                    if (table?.options?.meta?.updateUserRole) table?.options?.meta?.updateUserRole(role, uid)
                  }}
                >
                  <div className={`p-2 cursor-pointer ${roles[roles.length - 1] === role ? 'text-secondary' : ''}`}>
                    <div className={`flex justify-between items-center gap-4 !w-[300px] font-semibold`}>{role}</div>
                  </div>
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    size: 300,
  },
]
