import React, { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'

import '@/services/firebase'
// Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { arbitrum, mainnet } from 'wagmi/chains'
// Tailwind css
import './tailwind.css'
import './styles/globals.css'

import './styles/demoComponents.css'

// i18n (needs to be bundled)
import './i18n'

// Router
import { RouterProvider } from 'react-router-dom'
import router from './router/index'

// Redux
import * as Sentry from '@sentry/react'
import { FirebaseAuthProvider } from '@/utils/firebaseAuthProvider'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { Provider } from 'react-redux'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { persister, queryClient } from '@/services/api'
import { persistor, store } from '@/store'
import { PersistGate } from 'redux-persist/integration/react'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { QueryClientProvider } from '@tanstack/react-query'
import SolanaContextProvider from '@/components/wallet/SolanaContextProvider'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [import.meta.env.VITE_APP_GRAPHQL_URL],
      networkRequestHeaders: ['*'],
      networkResponseHeaders: ['*'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'https://dora-test.vercel.app/'],
  // Session Replay
  initialScope: {
    extra: {
      env_runtime: import.meta.env.MODE,
      env: import.meta.env.ENVIRONMENT,
      api: import.meta.env.VITE_APP_API_URL,
    },
  },
  environment: import.meta.env.ENVIRONMENT,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

document.addEventListener('DOMContentLoaded', function () {
  const metaTag = document.querySelector('meta[name="url"]')
  const metaTagOg = document.querySelector('meta[name="og:url"]')
  if (metaTag) {
    metaTag.setAttribute('content', window.location.origin)
  }

  if (metaTagOg) {
    metaTagOg.setAttribute('content', window.location.origin)
  }
})

const projectId = '3bc25b4daf61b8e99699d89affc2a578'

export const wcMetadata = {
  name: 'Dora',
  description: 'Dora - Bond AMM',
  url: 'https://dora-test.vercel.app', // origin must match your domain & subdomain
  icons: ['https://dora-test.vercel.app/favicon.png'],
}

const chains = [mainnet, arbitrum] as const
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata: wcMetadata,
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
})
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <RainbowKitProvider>
          <SolanaContextProvider>
            <FirebaseAuthProvider>
              <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
                <ReactQueryDevtools initialIsOpen={false} />
                <Suspense>
                  <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                      <RouterProvider router={router} />
                    </PersistGate>
                  </Provider>
                </Suspense>
              </PersistQueryClientProvider>
            </FirebaseAuthProvider>
          </SolanaContextProvider>
        </RainbowKitProvider>
      </WagmiProvider>
    </QueryClientProvider>
  </StrictMode>,
)
