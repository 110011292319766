import React, { FormEvent, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Asset, AssetType, getSdk } from '@/graphql/generated/graphql-request'
import { removeLettersForInput } from '@/utils/inputUtils'
import { useAllAssets } from '@/hooks/useAsset'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { removeFormatNumber } from '@/utils/numberUtils'

type Inputs = {
  baseAsset: string
  baseAssetAmount: string
  quoteAsset: string
  quoteAssetAmount: string
  swapFee: string
}

export const CreateLiquidityPoolForm = () => {
  const { allAssets } = useAllAssets()
  const [selectedbaseAsset, setSelectedbaseAsset] = useState(allAssets[0]?.symbol ?? '')
  const [selectedquoteAsset, setSelectedquoteAsset] = useState(allAssets[1]?.symbol ?? '')

  const nonPoolAssets = allAssets.filter((asset: Asset) => asset?.type !== AssetType.PoolShare)

  const validationSchema = z
    .object({
      baseAsset: z.string().min(1, 'Please select a bond'),
      baseAssetAmount: z.string().min(1, 'Please insert an amount'),
      quoteAssetAmount: z.string().min(1, 'Please insert an amount'),
      quoteAsset: z.string().min(1, 'Please select a bond'),
      swapFee: z.string().min(1, 'Please insert a swap fee'),
    })
    .refine((data) => data.baseAsset !== data.quoteAsset, {
      message: 'Please select different bonds',
      path: ['quoteAsset'],
    })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(validationSchema),
  })

  const handleInputChange = (e: FormEvent<HTMLInputElement>, field: keyof Inputs) => {
    const inputValue = e.currentTarget.value
    const sanitizedInput = removeLettersForInput(inputValue)
    setValue(field, sanitizedInput)
  }

  const mutation = useMutation({
    mutationFn: ({
      baseAssetID,
      baseAssetAmount,
      quoteAssetID,
      quoteAssetAmount,
      swapFee,
    }: {
      baseAssetID: string
      baseAssetAmount: string
      quoteAssetID: string
      quoteAssetAmount: string
      swapFee: string
    }) =>
      getSdk(graphQLClient)
        .CreatePool({
          input: {
            //TODO - check ammProp later
            baseAsset: {
              amount: baseAssetAmount,
              assetID: baseAssetID,
            },
            quoteAsset: {
              amount: quoteAssetAmount,
              assetID: quoteAssetID,
            },
            prodProp: {
              swapFee: Number.isNaN(swapFee) ? 0 : Number(swapFee),
            },
          },
        })
        .then(() => {
          createNotification(
            'Create Pool ',
            {
              baseAssetAmount,
              quoteAssetAmount,
              baseAssetID,
              quoteAssetID,
              swapFee,
            },
            NotificationType.CREATE_POOL,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on CreatePool'
          createNotification('Error on CreatePool', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['POOLS'] }).finally()
      queryClient.invalidateQueries({ queryKey: ['ASSETS'] }).finally()
    },
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const baseAsset = allAssets.find((a) => a.uid === data.baseAsset)
    const quoteAsset = allAssets.find((a) => a.uid === data.quoteAsset)

    const baseAssetAmount = Number(removeFormatNumber(data.baseAssetAmount)) * Math.pow(10, baseAsset?.decimals ?? 0)
    const quoteAssetAmount = Number(removeFormatNumber(data.quoteAssetAmount)) * Math.pow(10, quoteAsset?.decimals ?? 0)

    const payload = {
      baseAssetID: data.baseAsset,
      baseAssetAmount: baseAssetAmount.toString(),
      quoteAssetID: data.quoteAsset,
      quoteAssetAmount: quoteAssetAmount.toString(),
      swapFee: removeFormatNumber(data.swapFee),
    }

    console.log('create pool payload', payload)
    mutation.mutate(payload)
  }

  return (
    <div className='w-full'>
      <div className='panel grid grid-cols-1 xl:grid-cols-12 dark:bg-card h-full items-center'>
        <h1 className='col-span-1 xl:col-span-2 dark:text-white text-lg font-semibold'>Create liquidity pool</h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='w-full col-span-1 xl:col-span-10 grid grid-cols-1 xl:grid-cols-12'
        >
          {/*ASSET 1*/}
          <div className='flex flex-col gap-2 items-start col-span-1 xl:col-span-3'>
            <div className='relative flex flex-col'>
              <div className='flex flex-row justify-between'>
                <div className='min-w-[90px] text-left mr-2 mt-2'>Asset</div>
                <select
                  {...register('baseAsset', { required: true })}
                  value={selectedbaseAsset}
                  onChange={(e) => setSelectedbaseAsset(e.target.value)}
                  className='min-w-[300px] border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                >
                  {nonPoolAssets.map((asset: Asset) => (
                    <option key={'create_pool_option_first' + asset.uid} value={asset?.uid}>
                      {asset?.symbol}
                    </option>
                  ))}
                </select>
              </div>
              <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
                {errors.baseAsset ? errors.baseAsset.message : '\u00a0'}
              </span>
            </div>

            {/*AMOUNT 1*/}
            <div className='relative flex flex-col'>
              <div className='relative flex flex-row '>
                <div className='flex justify-between'>
                  <div className='min-w-[90px] text-left mr-2 mt-2'>Amount</div>
                  <input
                    id='baseAssetAmount'
                    {...register('baseAssetAmount', { required: true })}
                    onInput={(e) => handleInputChange(e, 'baseAssetAmount')}
                    className='min-w-[300px] border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
                {errors.baseAssetAmount ? errors.baseAssetAmount.message : '\u00a0'}
              </span>
            </div>
          </div>

          {/*ASSET 2*/}
          <div className='xl:ms-8 flex flex-col gap-2 justify-start items-start col-span-1 xl:col-span-3'>
            <div className='relative flex flex-col'>
              <div className='flex flex-row justify-between'>
                <div className='min-w-[90px] text-left mr-2 mt-2'>Asset</div>
                <select
                  {...register('quoteAsset', { required: true })}
                  value={selectedquoteAsset}
                  onChange={(e) => setSelectedquoteAsset(e.target.value)}
                  className='min-w-[300px] relative rounded-sm shadow-sm appearance-none form-select peer bg-card placeholder:tracking-widest border valid:border-dark'
                >
                  {nonPoolAssets.map((asset: Asset) => (
                    <option key={'create_pool_option_second' + asset.uid} value={asset?.uid}>
                      {asset?.symbol}
                    </option>
                  ))}
                </select>
              </div>
              <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
                {errors.quoteAsset ? errors.quoteAsset.message : '\u00a0'}
              </span>
            </div>

            {/*AMOUNT 2*/}
            <div className='relative flex flex-col'>
              <div className='relative flex flex-row items-center'>
                <div className='flex justify-between'>
                  <div className='min-w-[90px] text-left mr-2 mt-2'>Amount</div>
                  <input
                    id='quoteAssetAmount'
                    {...register('quoteAssetAmount', { required: true })}
                    onInput={(e) => handleInputChange(e, 'quoteAssetAmount')}
                    className='min-w-[300px] border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
              <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
                {errors.quoteAssetAmount ? errors.quoteAssetAmount.message : '\u00a0'}
              </span>
            </div>
          </div>

          {/*SWAP FEE*/}
          <div className='relative flex xl:mt-0 flex-row xl:ms-8 col-span-1 xl:col-span-4 xl:ps-6'>
            <div className='relative flex flex-col'>
              <div className='flex flex-row justify-center items-center'>
                <div className='ms-2 min-w-[90px] xl:min-w-[70px] text-left mb-6'>Swap Fee</div>
                <div className='flex flex-col'>
                  <input
                    id='swapFee'
                    {...register('swapFee', { required: true })}
                    onInput={(e) => handleInputChange(e, 'swapFee')}
                    className='min-w-[300px] xl:min-w-[200px] border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                  <span className='w-full text-red-500 text-end' style={{ minHeight: '1rem' }}>
                    {errors.swapFee ? errors.swapFee.message : '\u00a0'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex items-center col-span-1 xl:col-span-2 mt-2 xl:mt-0'>
            <button
              type='submit'
              className='w-full btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] my-4}'
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
