import { useQuery } from '@tanstack/react-query'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'
import { getSdk, User } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useQueryTime } from '@/hooks/useQueryTime'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '@/services/api'
import { getAuth, signOut } from 'firebase/auth'
import { useEffect } from 'react'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const useUser = () => {
  const user = useFirebaseAuth()
  const navigate = useNavigate()
  const {
    isLoading: loadingUser,
    isFetching,
    data: userData,
    status,
    error,
    isError,
  } = useQuery({
    queryKey: ['USER'],
    //queryFn: () => getFirestoreUserData(user?.uid!),
    queryFn: () => getSdk(graphQLClient).GetUser({ userUid: user?.uid ?? '' }),
    enabled: !!user?.uid,
    retry: 0,
    select: (data) => {
      if (!data) {
        signOut(getAuth()).finally(() => {
          queryClient.clear()
        })
      } else {
        return data
      }
    },
  })

  useEffect(() => {
    if (isFetching) {
      const interval = setInterval(() => {
        signOut(getAuth()).finally(() => {
          queryClient.clear()
        })
        createNotification(
          'API is down',
          'Unable to retrieve user information.',
          NotificationType.SIMPLE,
          NotificationStatus.ERROR,
        )
        navigate('/auth/sign-in', { replace: true })
      }, 60000)
      return () => clearInterval(interval)
    }
  }, [isFetching])

  // TODO - This was causing a bunch of error logs and causing an infinite loop
  // if (isError || error) {
  //   console.log('useQuery', 'Error getting user from GraphQL API')
  //   createToast('Error', 'Error getting user from GraphQL API', 'danger')
  //   Sentry.setContext('useUser', {
  //     name: user?.displayName ?? 'no user',
  //     email: user?.email ?? 'no email',
  //     uid: user?.uid ?? 'no uid',
  //     error: error.message,
  //   })
  //
  //   signOut(getAuth()).finally(() => {
  //     queryClient.clear()
  //   })
  //   navigate('/auth/sign-in', { replace: true })
  // }

  useQueryTime(`['USER', ${user?.uid}]`, status)
  return { loadingUser, user: userData?.getUser as User }
}
