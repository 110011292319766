import { OrderSide, TradeTypes } from '@/constants/trade'
import { OrderType } from '@/graphql/generated/graphql-request'

export type ExecutionOption = {
  type: string
  string: string
}

export enum TradeBoundsState {
  PRICE_MIN_ERROR = 'PRICE_MIN_ERROR',
  PRICE_MAX_ERROR = 'PRICE_MAX_ERROR',
  AMOUNT_MAX_ERROR = 'SIZE_MIN_ERROR',
  NO_ERROR = 'NO_ERROR',
}

export type TradeFlags = {
  needsLimitPrice: boolean
  needsTrailingPercent: boolean
  needsTriggerPrice: boolean
  needsGoodUntil: boolean
  needsPostOnly: boolean
  needsReduceOnly: boolean
  needsSize: boolean
  needsAdvancedOptions: boolean
  needsLeverage: boolean
  needsBrackets: boolean
  hasTimeInForce: boolean
  needsExecution: boolean
  executionOptions: ExecutionOption[]
}

export const getTradeFlagsByTradeType = (selectedTradeType: TradeTypes): TradeFlags => {
  switch (selectedTradeType) {
    case TradeTypes.LIMIT:
      return {
        needsLimitPrice: true,
        needsTrailingPercent: false,
        needsTriggerPrice: false,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: true,
        needsBrackets: false,
        needsExecution: false,
        hasTimeInForce: true,
        executionOptions: [],
      }
    case TradeTypes.MARKET:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: false,
        needsGoodUntil: false,
        needsPostOnly: false,
        needsReduceOnly: true,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: true,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [],
      }
    case TradeTypes.STOP_LIMIT:
      return {
        needsLimitPrice: true,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.STOP_MARKET:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.TAKE_PROFIT:
      return {
        needsLimitPrice: true,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.TAKE_PROFIT_MARKET:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.ORDERBOOK:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: false,
        needsGoodUntil: false,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: false,
        needsAdvancedOptions: false,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: false,
        hasTimeInForce: false,
        executionOptions: [],
      }
    default:
      throw new Error('Invalid trade type')
  }
}

// Define the function overloads
export function getOrderTypeName(selectedTradeType: TradeTypes, selectedOrderSide: OrderSide): string
export function getOrderTypeName(orderType: OrderType): string

/**
 * Retrieves the name of the order type based on the provided trade type and order side or a specific order type.
 * This function supports two forms of input:
 * 1. A combination of `TradeTypes` and `OrderSide` to determine the order name based on trade and side.
 * 2. A single `OrderType` to directly determine the order name.
 *
 * Overloads:
 * - getOrderTypeName(selectedTradeType: TradeTypes, selectedOrderSide: OrderSide): string
 *   Determines the order name based on the trade type (e.g., LIMIT, MARKET) and the order side (BUY, SELL).
 *
 * - getOrderTypeName(orderType: OrderType): string
 *   Determines the order name directly from the order type (e.g., LimitBuy, MarketSell).
 *
 * @param {TradeTypes | OrderType} arg1 - The trade type (if combined with `OrderSide`) or the order type.
 * @param {OrderSide} [arg2] - The order side, required if the first argument is a trade type.
 * @returns {string} The name of the order type, such as 'Limit Buy' or 'Market Sell'.
 * @throws {Error} Throws an error if the arguments do not match expected types or combinations.
 *
 * Examples:
 * - Using trade type and order side:
 *   getOrderTypeName(TradeTypes.LIMIT, OrderSide.BUY) // Returns 'Limit Buy'
 *
 * - Using order type:
 *   getOrderTypeName(OrderType.MarketSell) // Returns 'Market Sell'
 */
export function getOrderTypeName(arg1: TradeTypes | OrderType, arg2?: OrderSide): string {
  if (typeof arg1 === 'string' && arg2 === undefined) {
    switch (arg1) {
      case OrderType.LimitBuy:
        return 'Limit Buy'
      case OrderType.LimitSell:
        return 'Limit Sell'
      case OrderType.MarketBuy:
        return 'Market Buy'
      case OrderType.MarketSell:
        return 'Market Sell'
      default:
        throw new Error('Invalid order type')
    }
  } else if (typeof arg1 === 'string' && typeof arg2 === 'string') {
    return arg1 === TradeTypes.LIMIT
      ? arg2 === OrderSide.BUY
        ? 'Limit Buy'
        : 'Limit Sell'
      : arg2 === OrderSide.BUY
        ? 'Market Buy'
        : 'Market Sell'
  } else {
    throw new Error('Invalid arguments')
  }
}

/** Determine the order type based on the selected trade type and order side.
 If the selected trade type is LIMIT:
 - If the order side is BUY, the order type is set to LimitBuy.
 - If the order side is not BUY (i.e., SELL), the order type is set to LimitSell.
 If the selected trade type is not LIMIT (i.e., MARKET):
 - If the order side is BUY, the order type is set to MarketBuy.
 - If the order side is not BUY (i.e., SELL), the order type is set to MarketSell.
 **/
export const getOrderType = (selectedTradeType: TradeTypes, selectedOrderSide: OrderSide) => {
  return selectedTradeType === TradeTypes.LIMIT
    ? selectedOrderSide === OrderSide.BUY
      ? OrderType.LimitBuy
      : OrderType.LimitSell
    : selectedOrderSide === OrderSide.BUY
      ? OrderType.MarketBuy
      : OrderType.MarketSell
}
