import { ThemeName } from '@/tradingview/charting_library'
import { ChartLineType } from '@/constants/tvchart'
import { OrderSide } from '@/constants/trade'
const theme = {
  black: '#000000',
  white: '#FFFFFF',
  green: '#3ED9A4',
  red: '#E45555',
  layer0: '#08080C',
  layer1: '#101018',
  layer2: '#181825',
  layer3: '#212131',
  layer4: '#29293D',
  layer5: '#303045',
  layer6: '#38384D',
  layer7: '#464659',
  borderDefault: '#303045',
  borderDestructive: '#E4555533',
  borderButton: '#FFFFFF33',
  textPrimary: '#FAFAFD',
  textSecondary: '#C8C7D8',
  textTertiary: '#807E98',
  textButton: '#FAFAFD',
  gradientBase0: '#23232F',
  gradientBase1: '#303045',
  accent: '#6966FF',
  accentFaded: '#6966FF29',
  favorite: '#FFCC48',
  success: '#3ED9A4',
  warning: '#FFCC48',
  error: '#E45555',
  successFaded: '#3ED9A429',
  warningFaded: '#FFCC4829',
  errorFaded: '#E4555529',
  positive: '#3ED9A4',
  negative: '#E45555',
  positiveFaded: '#3ED9A429',
  negativeFaded: '#E4555529',
  riskLow: '#3ED9A4',
  riskMedium: '#FFCC48',
  riskHigh: '#E45555',
  logoFill: '#FFFFFF',
  profileYellow: '#FFB647',
  profileRed: '#E45555',
  inputBackground: '#29293D',
  popoverBackground: '#212131E6',
  toggleBackground: '#29293D',
  tooltipBackground: '#29293DA8',
  hoverFilterBase: '1.1',
  hoverFilterVariant: '1.1',
  activeFilter: '0.9',
}
export enum AppTheme {
  Classic = 'Classic',
  Dark = 'Dark',
  Light = 'Light',
}
export enum AppColorMode {
  GreenUp = 'GreenUp',
  RedUp = 'RedUp',
}
export const THEME_NAMES: Record<AppTheme, ThemeName> = {
  [AppTheme.Classic]: 'Classic',
  [AppTheme.Dark]: 'Dark',
  [AppTheme.Light]: 'Light',
}
export const getWidgetOverrides = ({ appTheme, appColorMode }: { appTheme: AppTheme; appColorMode: AppColorMode }) => {
  return {
    theme: THEME_NAMES[appTheme],
    overrides: {
      'paneProperties.background': theme.layer2,
      'paneProperties.horzGridProperties.color': theme.layer3,
      'paneProperties.vertGridProperties.color': theme.layer3,
      'paneProperties.crossHairProperties.style': 1,
      'paneProperties.legendProperties.showBarChange': false,
      'paneProperties.backgroundType': 'solid',

      'mainSeriesProperties.style': 1,
      'mainSeriesProperties.candleStyle.upColor': theme.positive,
      'mainSeriesProperties.candleStyle.borderUpColor': theme.positive,
      'mainSeriesProperties.candleStyle.wickUpColor': theme.positive,
      'mainSeriesProperties.candleStyle.downColor': theme.negative,
      'mainSeriesProperties.candleStyle.borderDownColor': theme.negative,
      'mainSeriesProperties.candleStyle.wickDownColor': theme.negative,
      'mainSeriesProperties.statusViewStyle.symbolTextSource': 'ticker',

      'scalesProperties.textColor': theme.textPrimary,
      'scalesProperties.backgroundColor': theme.layer2,
      'scalesProperties.lineColor': theme.layer3,
    },
    studies_overrides: {
      'volume.volume.color.0': theme.negative,
      'volume.volume.color.1': theme.positive,
      'volume.volume ma.visible': false,
      'relative strength index.plot.color': theme.accent,
      'relative strength index.plot.linewidth': 1.5,
      'relative strength index.hlines background.color': '#134A9F',
    },
    loading_screen: {
      backgroundColor: theme.layer2,
      foregroundColor: theme.layer2,
    },
  }
}
export const getChartLineColors = ({
  appTheme,
  appColorMode,
  chartLineType,
}: {
  appTheme: AppTheme
  appColorMode: AppColorMode
  chartLineType: ChartLineType
}) => {
  const orderColors = {
    [OrderSide.BUY]: theme.positive,
    [OrderSide.SELL]: theme.negative,
    ['entry']: null,
    ['liquidation']: theme.warning,
  }

  return {
    maybeQuantityColor: orderColors[chartLineType],
    borderColor: theme.borderDefault,
    backgroundColor: theme.layer1,
    textColor: theme.textTertiary,
    textButtonColor: theme.textButton,
  }
}

export const tradingViewSupportedTimezones = [
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Johannesburg',
  'Africa/Lagos',
  'America/Anchorage',
  'America/Argentina/Buenos_Aires',
  'America/Bogota',
  'America/Caracas',
  'America/Chicago',
  'America/El_Salvador',
  'America/Juneau',
  'America/Lima',
  'America/Los_Angeles',
  'America/Mexico_City',
  'America/New_York',
  'America/Phoenix',
  'America/Santiago',
  'America/Sao_Paulo',
  'America/Toronto',
  'America/Vancouver',
  'Asia/Almaty',
  'Asia/Ashkhabad',
  'Asia/Bahrain',
  'Asia/Bangkok',
  'Asia/Chongqing',
  'Asia/Colombo',
  'Asia/Dhaka',
  'Asia/Dubai',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Jakarta',
  'Asia/Jerusalem',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Kolkata',
  'Asia/Kuwait',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Qatar',
  'Asia/Riyadh',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Tehran',
  'Asia/Tokyo',
  'Asia/Yangon',
  'Atlantic/Reykjavik',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Perth',
  'Australia/Sydney',
  'Etc/UTC',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Copenhagen',
  'Europe/Helsinki',
  'Europe/Istanbul',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Warsaw',
  'Europe/Zurich',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Fakaofo',
  'Pacific/Honolulu',
  'Pacific/Norfolk',
  'US/Mountain',
]

/**
 * Calculates the GMT offset in minutes for a given timezone.
 *
 * @param {string} timezone - The IANA timezone string (e.g., 'Europe/Lisbon').
 * @returns {number} - The GMT offset in minutes. Returns 0 if the offset cannot be determined.
 */
const getGMTOffset = (timezone: string): number => {
  const date = new Date()
  const options = { timeZone: timezone, hour12: false, timeZoneName: 'short' as const }
  const formatter = new Intl.DateTimeFormat('en-US', options)
  const parts = formatter.formatToParts(date)
  const gmtString = parts.find((part) => part.type === 'timeZoneName')?.value
  const match = gmtString?.match(/GMT([+-]\d{1,2}):?(\d{2})?/)
  if (match) {
    const hours = parseInt(match[1], 10)
    const minutes = parseInt(match[2] || '0', 10)
    return hours * 60 + minutes
  }
  return 0
}

/**
 * Finds a supported timezone that matches the GMT offset of the user's timezone.
 * Prioritizes timezones that start with the same region as the user's timezone.
 *
 * @param {string} userTimezone - The IANA timezone string of the user (e.g., 'Europe/Lisbon').
 * @returns {string | null} - The first matching supported timezone, or null if no match is found.
 */
export const getSupportedTimezone = (userTimezone: string): string | null => {
  const userOffset = getGMTOffset(userTimezone)
  const userRegion = userTimezone.split('/')[0]
  const matchingTimezones: string[] = []

  for (const timezone of tradingViewSupportedTimezones) {
    if (getGMTOffset(timezone) === userOffset) {
      matchingTimezones.push(timezone)
    }
  }

  for (const timezone of matchingTimezones) {
    if (timezone.startsWith(userRegion)) {
      return timezone
    }
  }

  return matchingTimezones.length > 0 ? matchingTimezones[0] : null
}

export const getWidgetOptions = () => {
  return {
    // debug: true,
    container: 'tv-price-chart',
    library_path: '/tradingview/', // relative to public folder
    custom_css_url: '/tradingview/custom-styles.css',
    autosize: true,
    disabled_features: [
      'header_symbol_search',
      'header_compare',
      'symbol_search_hot_key',
      'compare_symbol',
      'symbol_info',
      'go_to_date',
      'timeframes_toolbar',
    ],
    enabled_features: [
      'remove_library_container_border',
      'hide_last_na_study_output',
      'dont_show_boolean_study_arguments',
      'hide_left_toolbar_by_default',
    ],
  }
}

export const getSavedResolution = ({ savedConfig }: { savedConfig?: object }): string | null => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const sources = (savedConfig?.charts ?? []).flatMap((chart: { panes: FixMe[] }) =>
    chart.panes.flatMap((pane) => pane.sources),
  )

  const savedResolution = sources.find(
    (source: { type: string; state: { interval: string | null } }) => source.type === 'MainSeries',
  )?.state?.interval

  return savedResolution ?? null
}
